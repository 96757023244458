import Layout from '@/views/layout/index.vue';
// import * as Ops from '@/plugins/v-permission/ops/index';
import taskListActiveIcon from '@/assets/menu/task-list-active-icon.svg';
import taskListInActiveIcon from '@/assets/menu/task-list-inactive-icon.svg';

const routes = [
  {
    path: '/task-list',
    name: 'TaskList',
    redirect: '/task-list/manage',
    meta: {
      menu: {
        name: '任务列表',
        icon: {
          active: taskListActiveIcon,
          inactive: taskListInActiveIcon,
        },
      },
      // permissions: Ops.or([

      // ]),
    },
    component: Layout,
    children: [
      {
        path: '/task-list/manage',
        name: 'TaskListManage',
        meta: {
          menu: {
            name: '任务列表',
            hidden: true,
          },
          breadcrumb: {
            label: '任务列表',
          },
        },
        component: () => import('@/views/task-list/index.vue'),
      },
    ],
  },
];

export default routes;
