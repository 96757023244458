const routes = [
  {
    path: '/ca-login',
    name: 'ca-login',
    component: () => import('@/views/ca-login.vue'),
  },
  {
    path: '/ca-select-org',
    name: 'ca-select-org',
    component: () => import('@/views/ca-select-org.vue'),
  },
  {
    path: '/',
    redirect: '/homepage',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/login-safe',
    name: 'LoginSafe',
    component: () => import('@/views/login/login-safe.vue'),
  },
  {
    path: '/login-2fa',
    name: 'Login2fa',
    component: () => import('@/views/login/login-2fa.vue'),
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import('@/views/login/forget-password.vue'),
  },
  {
    path: '/need-change-password',
    component: () => import('@/views/force-change-password/index.vue'),
  },
];

export default routes;
