import ServiceOrganizationManagementActiveIcon from '@/assets/menu/service-organization-management-active-icon.svg';
import ServiceOrganizationManagementInactiveIcon from '@/assets/menu/service-organization-management-inactive-icon.svg';
import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import ServiceProjectManagement from '@/views/service-organization-management/service-project-management/index.vue';
import ServiceReservationProcessing from '@/views/service-organization-management/service-reservation-processing/index.vue';
import ServiceOrganizationRegistration from '@/views/service-organization-management/service-organization-registration/index.vue';
import ServiceOrganizationRegistrationEdit from '@/views/service-organization-management/service-organization-registration/edit/index.vue';
import ServiceReservationProcessingHandler from '@/views/service-organization-management/service-reservation-processing/handle/index.vue';
import ServiceEvaluationManagement from '@/views/service-organization-management/service-evaluation-management/evaluation.vue';
import ServiceEvaluationDetail from '@/views/service-organization-management/service-evaluation-management/detail/detail.vue';

const routes = [
  {
    name: 'ServiceOrganizationManagement',
    path: '/service-organization-management',
    redirect: '/service-organization-management/service-organization-registration',
    meta: {
      menu: {
        name: '服务组织管理',
        icon: {
          active: ServiceOrganizationManagementActiveIcon,
          inactive: ServiceOrganizationManagementInactiveIcon,
        },
      },
      permissions: Ops.or([
        '服务组织登记',
        '志愿服务项目管理',
        '服务预约处理',
        '服务评价管理',
      ]),
    },
    component: Layout,
    children: [
      {
        name: 'ServiceOrganizationRegistration',
        path: '/service-organization-management/service-organization-registration',
        meta: {
          menu: {
            name: '服务组织登记',
          },
          breadcrumb: {
            label: '服务组织登记',
          },
          permissions: '服务组织登记',
        },
        component: ServiceOrganizationRegistration,
      },
      {
        name: 'ServiceOrganizationRegistrationEdit',
        path: '/service-organization-management/service-organization-registration/edit',
        meta: {
          menu: {
            name: '编辑信息',
            hidden: true,
          },
          breadcrumb: {
            label: '编辑信息',
            parent: 'ServiceOrganizationRegistration',
          },
          activeMenu: '/service-organization-management/service-organization-registration',
          permissions: '服务组织登记',
        },
        component: ServiceOrganizationRegistrationEdit,
      },
      {
        name: 'ServiceProjectManagement',
        path: '/service-organization-management/service-project-management',
        meta: {
          menu: {
            name: '志愿服务项目管理',
          },
          breadcrumb: {
            label: '志愿服务项目管理',
          },
          permissions: '志愿服务项目管理',

        },
        component: ServiceProjectManagement,
      },
      {
        name: 'ServiceReservationProcessing',
        path: '/service-organization-management/service-reservation-processing',
        meta: {
          menu: {
            name: '服务预约处理',
          },
          breadcrumb: {
            label: '服务预约处理',
          },
          permissions: '服务预约处理',
        },
        component: ServiceReservationProcessing,
      },
      {
        name: 'ServiceReservationProcessingHandler',
        path: '/service-organization-management/service-reservation-processing/handler/:serviceId',
        props: true,
        meta: {
          menu: {
            name: '处理预约',
            hidden: true,
          },
          breadcrumb: {
            label: '处理预约',
            parent: 'ServiceReservationProcessing',
          },
          activeMenu: '/service-organization-management/service-reservation-processing',
          permissions: '服务预约处理',
        },
        component: ServiceReservationProcessingHandler,
      },
      {
        name: 'ServiceEvaluationManagement',
        path: '/service-organization-management/service-evaluation-management',
        meta: {
          menu: {
            name: '服务评价管理',
          },
          breadcrumb: {
            label: '服务评价管理',
          },
          permissions: '服务评价管理',
        },
        component: ServiceEvaluationManagement,
      },
      {
        name: 'ServiceEvaluationDetail',
        path: '/service-organization-management/service-evaluation-management/detail/:id',
        props: true,
        meta: {
          menu: {
            name: '查看详情',
            hidden: true,
          },
          breadcrumb: {
            label: '查看详情',
            parent: 'ServiceEvaluationManagement',
          },
          activeMenu: '/service-organization-management/service-evaluation-management',
          permissions: '服务评价管理',
        },
        component: ServiceEvaluationDetail,
      },
    ],
  },
];

export default routes;
