<template>
  <div v-if="!isHidden">
    <template v-if="isItemShow(item)">
      <app-link
        v-if="onlyOneChild.meta.menu"
        :to="resolvePath(onlyOneChild.path)"
      >
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{'submenu-title-noDropdown':!isNest}"
        >
          <item
            :icon="onlyOneChild.meta.menu.icon||(item.meta.menu&&item.meta.menu.icon)"
            :title="onlyOneChild.meta.menu.name"
            :isActive="isSubActive"
          />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path)"
      popper-append-to-body
    >
      <template slot="title">
        <item
          v-if="item.meta.menu"
          :icon="item.meta.menu && item.meta.menu.icon"
          :title="item.meta.menu.name"
          :isActive="isActive"
        />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from 'path-browserify';
import { isExternal } from '@/utils/validate';
import Item from './item.vue';
import AppLink from './link.vue';
import FixiOSBug from './fixi-os-bug';

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: '',
    },
    activeMenu: {
      type: String,
    },
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {};
  },
  computed: {
    hasMenuMeta() {
      return this.item.meta?.menu;
    },
    isHidden() {
      if (this.hasMenuMeta) {
        return this.item.meta.menu.hidden;
      }
      return true;
    },
    isActive() {
      return this.item.children
        .map((item) => item.path)
        .includes(this.activeMenu);
    },
    isSubActive() {
      return this.activeMenu === this.resolvePath(this.onlyOneChild.path);
    },
  },
  methods: {
    isItemShow(item) {
      const hasOneShowingChild = this.hasOneShowingChild(item.children, item);
      const onlyOneLayerChild = !this.onlyOneChild.children || this.onlyOneChild.noShowingChildren;

      return hasOneShowingChild && onlyOneLayerChild && !item.alwaysShow;
    },
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.meta.hidden) {
          return false;
        }
        // Temp set(will be used if only has one showing child)
        this.onlyOneChild = item;
        return true;
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>
