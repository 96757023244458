export function getServerPageQuery(page, size) {
  return {
    skip: (page - 1) * size,
    limit: size,
  };
}

export function clone(object) {
  return JSON.parse(JSON.stringify(object));
}
