<template>
  <el-dialog
    ref="dialog"
    :title="title"
    :visible.sync="dialogVisible"
    width="385px"
    @closed="onDialogHide"
  >
    <el-form
      size="small"
      label-suffix=":"
      label-width="72px"
    >
      <el-form-item label="服务项目">
        {{ data.name }}
      </el-form-item>
      <el-form-item label="项目费用">
        {{ data.fee }}
      </el-form-item>
      <el-form-item label="服务内容">
        {{ data.content }}
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button
        size="small"
        @click="onCancelClick"
      >取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="onOKClick"
      >确定</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  props: {
    title: {
      default: '新建服务项目',
    },
    data: {
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },

    onDialogHide() {
      this.$emit('hide');
    },

    async onOKClick() {
      this.$emit('ok');

      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>

<style>
</style>
