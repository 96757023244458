const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  roles: (state) => state.user.roles,
  name: (state) => state.user.name,
  orgName: (state) => state.user.orgName,
  orgType: (state) => state.user.organizationType,
  account: (state) => state.user.account,
  positionList: (state) => state.user.positionList,
  menuList: (state) => state.user.menuList,
  permissions: (state) => state.user.permissions,
  token: (state) => state.user.token,
  depositReturn: (state) => state.deposit.depositReturn,
  depositPay: (state) => state.deposit.depositPay,
  currentCheckInTab: (state) => state.deposit.currentCheckInTab,
  routes: (state) => state.permission.routes,
  unreadMessage: (state) => state.user.unreadMessage,
  needChangePwd: (state) => state.user.needChangePwd,
};
export default getters;
