import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import governmentActiveIcon from '@/assets/menu/government-active-icon.svg';
import governmentInactiveIcon from '@/assets/menu/government-inactive-icon.svg';

const routes = [
  {
    path: '/affairs-report',
    name: 'AffairsReport',
    meta: {
      menu: {
        name: '政务上报',
        icon: {
          active: governmentActiveIcon,
          inactive: governmentInactiveIcon,
        },
      },
      permissions: Ops.or([
        '机构上报任务',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/affairs-report/organization-report-task',
        name: 'OrganizationReportTask',
        meta: {
          menu: {
            name: '机构上报任务',
          },
          breadcrumb: {
            label: '机构上报任务',
          },
          permissions: '机构上报任务',
        },
        component: () => import('@/views/affairs-report/index.vue'),
      },
      {
        path: '/affairs-report/organization-report-task/report/:reportid/:reporttype',
        name: 'TaskReport',
        props: true,
        meta: {
          menu: {
            name: '上报',
            hidden: true,
          },
          breadcrumb: {
            parent: 'OrganizationReportTask',
            label: '上报',
          },
          activeMenu: '/affairs-report/organization-report-task',
          permissions: '机构上报任务',
        },
        component: () => import('@/views/affairs-report/upload/index.vue'),
      },
      {
        path: '/affairs-report/organization-report-task/details/:detailsid/:detailstype',
        name: 'TaskDetails',
        props: true,
        meta: {
          menu: {
            name: '查看',
            hidden: true,
          },
          breadcrumb: {
            parent: 'OrganizationReportTask',
            label: '查看',
          },
          activeMenu: '/affairs-report/organization-report-task',
          permissions: '机构上报任务',
        },
        component: () => import('@/views/affairs-report/detail/index.vue'),
      },

    ],
  },
];

export default routes;
