import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import governmentActiveIcon from '@/assets/menu/government-active-icon.svg';
import governmentInactiveIcon from '@/assets/menu/government-inactive-icon.svg';

const routes = [
  {
    path: '/government-application',
    name: 'GovernmentApplication',
    meta: {
      menu: {
        name: '政务申请',
        icon: {
          active: governmentActiveIcon,
          inactive: governmentInactiveIcon,
        },
      },
      permissions: Ops.or([
        '门户入驻',
        '信息推送',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/government-application/entry-portal',
        name: 'EntryPortal',
        meta: {
          menu: {
            name: '门户入驻',
          },
          breadcrumb: {
            label: '门户入驻',
          },
          permissions: '门户入驻',
        },
        component: () => import('@/views/government-application/entry-portal/index.vue'),
      },
      {
        path: '/government-application/information-push',
        name: 'InformationPush',
        meta: {
          menu: {
            name: '信息推送',
          },
          breadcrumb: {
            label: '信息推送',
          },
          permissions: '信息推送',
        },
        component: () => import('@/views/government-application/information-push/index.vue'),
      },
      {
        path: '/government-application/information-push-add',
        name: 'InformationPushAdd',
        meta: {
          menu: {
            name: '新建推送',
            hidden: true,
          },
          breadcrumb: {
            parent: 'InformationPush',
            label: '新建推送',
          },
          activeMenu: '/government-application/information-push',
          permissions: '信息推送',
        },
        component: () => import('@/views/government-application/information-push/add/index.vue'),
      },
      {
        path: '/government-application/information-push-detail/:id',
        name: 'InformationPushDetail',
        props: true,
        meta: {
          menu: {
            name: '查看详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'InformationPush',
            label: '查看详情',
          },
          activeMenu: '/government-application/information-push',
          permissions: '信息推送',
        },
        component: () => import('@/views/government-application/information-push/detail/index.vue'),
      },
      {
        path: '/government-application/information-push-edit/:id',
        name: 'InformationPushEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑推送',
            hidden: true,
          },
          breadcrumb: {
            parent: 'InformationPush',
            label: '编辑推送',
          },
          activeMenu: '/government-application/information-push',
          permissions: '信息推送',
        },
        component: () => import('@/views/government-application/information-push/edit/index.vue'),
      },
    ],
  },
];

export default routes;
