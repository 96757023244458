import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import elderManagementActiveIcon from '@/assets/menu/elder-management-active-icon.svg';
import elderManagementInactiveIcon from '@/assets/menu/elder-management-inactive-icon.svg';

const routes = [
  {
    path: '/elderly-management',
    name: 'ElderlyManagement',
    redirect: '/elderly-management/elderly-overview',
    meta: {
      menu: {
        name: '老年人管理',
        icon: {
          active: elderManagementActiveIcon,
          inactive: elderManagementInactiveIcon,
        },
      },
      orgType: 'institutions',
      permissions: Ops.or([
        '老年人总览',
        '请假管理',
        '办理出院',
        '既往病史',
        '常规性测量',
        '体检报告',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/elderly-management/elderly-overview',
        name: 'ElderlyOverview',
        meta: {
          menu: {
            name: '老年人总览',
          },
          breadcrumb: {
            label: '老年人总览',
          },
          permissions: '老年人总览',
        },
        component: () => import('@/views/elderly-management/elderly-overview/index.vue'),
      },
      {
        path: '/elderly-management/elderly-overview/detail/:elderId',
        name: 'ElderlyOverviewDetail',
        props: true,
        meta: {
          menu: {
            name: '查看详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyOverview',
            label: '查看详情',
          },
          activeMenu: '/elderly-management/elderly-overview',
          permissions: '老年人总览',
        },
        component: () => import('@/views/elderly-management/elderly-overview/detail/index.vue'),
      },
      {
        path: '/elderly-management/elderly-overview/edit/:elderId',
        name: 'ElderlyOverviewEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑老人',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyOverview',
            label: '编辑老人',
          },
          activeMenu: '/elderly-management/elderly-overview',
          permissions: '老年人总览',
        },
        component: () => import('@/views/elderly-management/elderly-overview/edit/index.vue'),
      },
      {
        path: '/elderly-management/leave-management',
        name: 'LeaveManagement',
        meta: {
          menu: {
            name: '请假管理',
          },
          breadcrumb: {
            label: '请假管理',
          },
          permissions: '请假管理',
        },
        component: () => import('@/views/elderly-management/leave-management/index.vue'),
      },
      {
        path: '/elderly-management/leave-management/record/:id',
        name: 'LeaveManagementRecord',
        props: true,
        meta: {
          menu: {
            name: '请假记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'LeaveManagement',
            label: '请假记录',
          },
          activeMenu: '/elderly-management/leave-management',
          permissions: '请假管理',
        },
        component: () => import('@/views/elderly-management/leave-management/record/index.vue'),
      },
      {
        path: '/elderly-management/handler-discharge',
        name: 'HandlerDischarge',
        meta: {
          menu: {
            name: '办理出院',
          },
          breadcrumb: {
            label: '办理出院',
          },
          permissions: '办理出院',
        },
        component: () => import('@/views/elderly-management/handler-discharge/index.vue'),
      },
      {
        path: '/elderly-management/medical-history/list',
        name: 'MedicalHistoryList',
        meta: {
          menu: {
            name: '既往病史',

          },
          breadcrumb: {
            label: '既往病史',
          },
          permissions: '既往病史',
        },
        component: () => import('@/views/medical-history/index.vue'),
      },
      {
        path: '/elderly-management/medical-history/list/:elderId/edit',
        name: 'MedicalHistoryEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑病史',
            hidden: true,
          },
          breadcrumb: {
            label: '编辑病史',
            parent: 'MedicalHistoryList',
          },
          permissions: '既往病史',
        },
        component: () => import('@/views/medical-history/edit/index.vue'),
      },
      {
        path: '/elderly-management/routine-measurement/index',
        name: 'RoutineMeasurement',
        meta: {
          menu: {
            name: '常规性测量',
          },
          breadcrumb: {
            label: '常规性测量',
          },
          permissions: '常规性测量',
        },
        component: () => import('@/views/elderly-service/routine-measurement/index.vue'),
      },
      {
        path: '/elderly-management/routine-measurement/details/:id',
        name: 'RoutineMeasurementEdit',
        props: true,
        meta: {
          menu: {
            name: '测量记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'RoutineMeasurement',
            label: '测量记录',
          },
          activeMenu: '/elderly-management/routine-measurement/index',
          permissions: '常规性测量',
        },
        component: () => import('@/views/elderly-service/routine-measurement/details/index.vue'),
      },
      {
        path: '/elderly-management/physical-examination',
        name: 'PhysicalExamination',
        meta: {
          menu: {
            name: '体检报告',
          },
          breadcrumb: {
            label: '体检报告',
          },
          permissions: '体检报告',
        },
        component: () => import('@/views/elderly-management/physical-examination/index.vue'),
      },
      {
        path: '/elderly-management/physical-examination/details/:id',
        name: 'PhysicalExaminationDetails',
        props: true,
        meta: {
          menu: {
            name: '查看',
            hidden: true,
          },
          breadcrumb: {
            parent: 'PhysicalExamination',
            label: '查看',
          },
          activeMenu: '/elderly-management/physical-examination',
          permissions: '查看',
        },
        component: () => import('@/views/elderly-management/physical-examination/details/index.vue'),
      },
    ],
  },
];

export default routes;
