import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import elderServiceActiveIcon from '@/assets/menu/elder-service-active-icon.svg';
import elderServiceInActiveIcon from '@/assets/menu/elder-service-inactive-icon.svg';

const routes = [
  {
    path: '/elderly-health',
    name: 'ElderlyHealth',
    meta: {
      menu: {
        name: '老年人健康档案',
        icon: {
          active: elderServiceActiveIcon,
          inactive: elderServiceInActiveIcon,
        },
      },
      orgType: 'station',
      permissions: Ops.or([
        '入院咨询',
        '办理入院',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/elderly-health/admission-handler',
        name: 'ElderlyHealthAdmissionHandler',
        meta: {
          menu: {
            name: '长者签约',
          },
          breadcrumb: {
            label: '长者签约',
          },
          permissions: '办理入院',
        },
        component: () => import('@/views/elderly-service/admission-handler/index.vue'),
      },
      {
        path: '/elderly-health/admission-handler/initiate-admission',
        name: 'ElderlyHealthInitiateAdmission',
        meta: {
          menu: {
            name: '发起签约',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyHealthAdmissionHandler',
            label: '发起签约',
          },
          activeMenu: '/elderly-health/admission-handler',
        },
        component: () => import('@/views/elderly-service/admission-handler/components/user-overview/initiate-admission/index.vue'),
      },
      {
        path: '/elderly-health/admission-handler/detail/:id',
        name: 'ElderlyHealthElderlyDetail',
        props: true,
        meta: {
          menu: {
            name: '查看详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyHealthAdmissionHandler',
            label: '查看详情',
          },
          activeMenu: '/elderly-health/admission-handler',
        },
        component: () => import('@/views/elderly-service/admission-handler/components/user-overview/detail/index.vue'),
      },
      {
        path: '/elderly-health/admission-handler/edit/:id',
        name: 'ElderlyHealthElderlyEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑用户',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyHealthAdmissionHandler',
            label: '编辑用户',
          },
          activeMenu: '/elderly-health/admission-handler',
        },
        component: () => import('@/views/elderly-service/admission-handler/components/user-overview/edit-user-info/index.vue'),
      },
      {
        path: '/elderly-health/admission-handler/upload-materials/:recordId',
        name: 'ElderlyHealthUploadMaterials',
        props: true,
        meta: {
          menu: {
            name: '上传材料',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyHealthAdmissionHandler',
            label: '上传材料',
          },
          activeMenu: '/elderly-health/admission-handler',
        },
        component: () => import('@/views/elderly-service/admission-handler/components/upload-materials/upload/index.vue'),
      },
      {
        path: '/elderly-health/admission-handler/materials-upload-detail/:id',
        name: 'ElderlyHealthMaterialsDetail',
        props: true,
        meta: {
          menu: {
            name: '查看材料',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyHealthAdmissionHandler',
            label: '查看材料',
          },
          activeMenu: '/elderly-health/admission-handler',
        },
        component: () => import('@/views/elderly-service/admission-handler/components/upload-materials/detail/index.vue'),
      },
      {
        path: '/elderly-health/admission-handler/materials-upload-modify/:id',
        name: 'ElderlyHealthMaterialsModify',
        props: true,
        meta: {
          menu: {
            name: '编辑材料',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyHealthAdmissionHandler',
            label: '编辑材料',
          },
          activeMenu: '/elderly-health/admission-handler',
        },
        component: () => import('@/views/elderly-service/admission-handler/components/upload-materials/modify/index.vue'),
      },
      {
        path: '/elderly-health/admission-handler/audit-materials-detail/:id',
        name: 'ElderlyHealthAuditMaterialsDetail',
        props: true,
        meta: {
          menu: {
            name: '查看材料',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyHealthAdmissionHandler',
            label: '查看材料',
          },
          activeMenu: '/elderly-health/admission-handler',
        },
        component: () => import('@/views/elderly-service/admission-handler/components/audit-materials/detail/index.vue'),
      },
      {
        path: '/elderly-health/admission-handler/audit-materials-examine/:id',
        name: 'ElderlyHealthAuditMaterialsExamine',
        props: true,
        meta: {
          menu: {
            name: '审核材料',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyHealthAdmissionHandler',
            label: '审核材料',
          },
          activeMenu: '/elderly-health/admission-handler',
        },
        component: () => import('@/views/elderly-service/admission-handler/components/audit-materials/examine/index.vue'),
      },
      {
        path: '/elderly-health/admission-handler/sign-contract-detail/:id',
        name: 'ElderlyHealthSignContractDetail',
        props: true,
        meta: {
          menu: {
            name: '签订合同',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyHealthAdmissionHandler',
            label: '签订合同',
          },
          activeMenu: '/elderly-health/admission-handler',
        },
        component: () => import('@/views/elderly-service/admission-handler/components/sign-contract/sign/index.vue'),
      },
      {
        path: '/elderly-health/admission-handler/view-elderly-info/:id',
        name: 'ElderlyHealthViewElderlyInfo',
        props: true,
        meta: {
          menu: {
            name: '查看详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderlyHealthAdmissionHandler',
            label: '查看详情',
          },
          activeMenu: '/elderly-health/admission-handler',
        },
        component: () => import('@/views/elderly-service/admission-handler/components/confirm-check-in/detail/index.vue'),
      },
      {
        path: '/elderly-health/handler-discharge',
        name: 'elderlyHealthHandlerDischarge',
        meta: {
          menu: {
            name: '长者解约',
          },
          breadcrumb: {
            label: '长者解约',
          },
          permissions: '办理出院',
        },
        component: () => import('@/views/elderly-management/handler-discharge/index.vue'),
      },
      {
        path: '/elderly-health/medical-history/list',
        name: 'elderlyHealthMedicalHistoryList',
        meta: {
          menu: {
            name: '既往病史',

          },
          breadcrumb: {
            label: '既往病史',
          },
          permissions: '既往病史',
        },
        component: () => import('@/views/medical-history/index.vue'),
      },
      {
        path: '/elderly-health/medical-history/list/:elderId/edit',
        name: 'elderlyHealthMedicalHistoryEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑病史',
            hidden: true,
          },
          breadcrumb: {
            label: '编辑病史',
            parent: 'elderlyHealthMedicalHistoryList',
          },
          permissions: '既往病史',
        },
        component: () => import('@/views/medical-history/edit/index.vue'),
      },
      {
        path: '/elderly-health/routine-measurement/index',
        name: 'elderlyHealthRoutineMeasurement',
        meta: {
          menu: {
            name: '常规性测量',
          },
          breadcrumb: {
            label: '常规性测量',
          },
          permissions: '常规性测量',
        },
        component: () => import('@/views/elderly-service/routine-measurement/index.vue'),
      },
      {
        path: '/elderly-health/routine-measurement/details/:id',
        name: 'elderlyHealthRoutineMeasurementEdit',
        props: true,
        meta: {
          menu: {
            name: '测量记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'elderlyHealthRoutineMeasurement',
            label: '测量记录',
          },
          activeMenu: '/elderly-health/routine-measurement/index',
          permissions: '常规性测量',
        },
        component: () => import('@/views/elderly-service/routine-measurement/details/index.vue'),
      },
      {
        path: '/elderly-health/physical-examination',
        name: 'elderlyHealthPhysicalExamination',
        meta: {
          menu: {
            name: '体检报告',
          },
          breadcrumb: {
            label: '体检报告',
          },
          permissions: '体检报告',
        },
        component: () => import('@/views/elderly-management/physical-examination/index.vue'),
      },
      {
        path: '/elderly-health/physical-examination/details/:id',
        name: 'elderlyHealthPhysicalExaminationDetails',
        props: true,
        meta: {
          menu: {
            name: '查看',
            hidden: true,
          },
          breadcrumb: {
            parent: 'elderlyHealthPhysicalExamination',
            label: '查看',
          },
          activeMenu: '/elderly-health/physical-examination',
          permissions: '查看',
        },
        component: () => import('@/views/elderly-management/physical-examination/details/index.vue'),
      },
      {
        path: '/elderly-health/building-info',
        name: 'elderlyHealthBuildingInfo',
        meta: {
          menu: {
            name: '地址管理',
          },
          breadcrumb: {
            label: '辖区管理',
          },
          permissions: '楼宇信息',
        },
        component: () => import('@/views/institutions/building-info/index.vue'),
      },
      {
        path: '/elderly-health/building-info/:buildingId/floor-manage',
        name: 'elderlyHealthFloorManage',
        props: true,
        meta: {
          menu: {
            name: '街道管理',
            hidden: true,
          },
          breadcrumb: {
            label: '街道管理',
            parent: 'elderlyHealthBuildingInfo',
          },
          activeMenu: '/elderly-health/building-info',
          permissions: '楼宇信息',
        },
        component: () => import('@/views/institutions/building-info/floor-manage/index.vue'),
      },
      {
        path: '/elderly-health/building-info/:buildingId/floor-manage/:floorId/room-manage',
        name: 'elderlyHealthRoomManage',
        props: true,
        meta: {
          menu: {
            name: '社区管理',
            hidden: true,
          },
          breadcrumb: {
            label: '社区管理',
            parent: 'elderlyHealthFloorManage',
          },
          activeMenu: '/elderly-health/building-info',
          permissions: '楼宇信息',
        },
        component: () => import('@/views/institutions/building-info/floor-manage/room-manage/index.vue'),
      },
      {
        path: '/elderly-health/building-info/:buildingId/floor-manage/:floorId/room-manage/:roomId/bed-manage',
        name: 'elderlyHealthbedManage',
        props: true,
        meta: {
          menu: {
            name: '住址管理',
            hidden: true,
          },
          breadcrumb: {
            label: '住址管理',
            parent: 'elderlyHealthRoomManage',
          },
          activeMenu: '/elderly-health/building-info',
          permissions: '楼宇信息',
        },
        component: () => import('@/views/institutions/building-info/floor-manage/room-manage/bed-manage/index.vue'),
      },
    ],
  },
];

export default routes;
