<template>
  <div class="service-reservation-processing-handler app-container">
    <el-card shadow="never">
      <div class="title">预约信息</div>
      <el-form
        label-suffix=":"
        size="small"
      >
        <el-form-item label="服务组织">{{ data.serviceOrgName }}</el-form-item>
        <el-form-item label="服务项目">{{ data.serviceOrgItemName }}</el-form-item>
        <el-form-item label="服务内容">{{ data.reservationContent }}</el-form-item>
        <el-form-item label="预约机构/驿站">{{ data.reservationOrgName }}</el-form-item>
        <el-form-item label="老人姓名">{{ data.elderName }}</el-form-item>
        <el-form-item label="性别">{{ data.gender }}</el-form-item>
        <el-form-item label="联系方式">{{ data.phone }}</el-form-item>
        <el-form-item label="老人住址">{{ data.address }}</el-form-item>
        <el-form-item label="服务时间">{{ formatterTime(data.reservationServiceTime) }}</el-form-item>
      </el-form>
      <div class="title">分配信息</div>
      <el-form
        label-suffix=":"
        size="small"
      >
        <el-form-item
          label="分配人员"
          required
          :error="$et($v.form.staffId)"
        >
          {{ form.staffName }}
          <el-button
            type="primary"
            @click="onChoiceClick"
          >选择</el-button>
        </el-form-item>
        <el-form-item
          label="服务时间"
          required
          :error="$et($v.form.serviceTime)"
        >
          <el-date-picker
            type="datetime"
            v-model="form.serviceTime"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          size="small"
          @click="onCancelClick"
        >取消</el-button>
        <el-button
          :loading="loading"
          size="small"
          type="primary"
          @click="onConfirmClick"
        >确定</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ChoiceDialog from '@/components/choice-department.vue';
import { getOrgReservationServiceRecordDetail } from '@/api/org-reservation-service​';
import { disposeReservation } from '@/api/service-org';
import errorHandler from '@/plugins/error-handler';
import { formatterDateTime } from '@/utils/formatter-time';

export default {
  name: 'ServiceReservationProcessingHandler',
  props: {
    serviceId: {
      required: true,
    },
  },
  data() {
    return {
      form: {
        staffName: '',
        staffId: '',
        serviceTime: '',
      },

      loading: false,

      data: {
        elderName: '',
        gender: '',
        phone: '',
        address: '',
        serviceOrgItemName: '',
        reservationServiceTime: '',
        reservationContent: '',
        reservationOrgName: '',
        status: '',
      },
    };
  },
  validations: {
    form: {
      staffId: {
        required,
      },
      serviceTime: {
        required,
      },
    },
  },
  errors: {
    required: '该项不能为空',
  },
  created() {
    this.getOrgReservationServiceRecordDetail();
  },
  methods: {
    async getOrgReservationServiceRecordDetail() {
      try {
        const { data } = await getOrgReservationServiceRecordDetail(this.serviceId);
        this.data = {
          ...this.data,
          ...data.data,
        };
      } catch (error) {
        errorHandler('获取记录详情失败', error);
      }
    },

    formatterTime(time) {
      if (time) {
        return formatterDateTime(time);
      }
      return '';
    },

    onChoiceClick() {
      this.$z.$dialog({
        parent: this,
        component: ChoiceDialog,
      })
        .onOk((row) => {
          this.form.staffName = row.name;
          this.form.staffId = row.id;
        });
    },

    onCancelClick() {
      this.$router.back();
    },

    async onConfirmClick() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.loading = true;
        const params = {
          id: this.serviceId,
          data: {
            staffId: this.form.staffId,
            serviceTime: this.form.serviceTime,
          },
        };
        await disposeReservation(params);
        this.$message.success('分配成功');
        this.$router.back();
      } catch (error) {
        console.error(error);
        errorHandler('分配失败', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.service-reservation-processing-handler {
  .title {
    font-weight: bold;
  }
}
</style>
