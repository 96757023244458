import Vue from 'vue';

import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  PieChart,
  BarChart,
  LineChart,
} from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';

import Permission from '@/plugins/v-permission';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/plugins/dialog';
import '@/plugins/element-ui';
import '@/plugins/vuelidate';
import '@/plugins/vuelidate-errors';
import '@/plugins/vue-countdown';
import '@/plugins/router-permission';

import '@/utils/request';
import '@/utils/request-interceptors';

import '@/styles/index.scss';

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent,
]);

Vue.use(Permission, {
  store,
  getters: {
    permissions: 'permissions',
    roles: 'roles',
  },
});
Vue.component('v-chart', VChart);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
