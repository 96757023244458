<template>
  <el-dialog
    ref="dialog"
    :title="title"
    :visible.sync="dialogVisible"
    width="385px"
    @closed="onDialogHide"
  >
    <el-form
      label-suffix=":"
      size="small"
      label-width="110px"
      label-position="left"
    >
      <el-form-item
        label="预期服务内容"
        required
        :error="$et($v.form.reservationContent)"
      >
        <el-input
          class="form-input-item"
          v-model="form.reservationContent"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="预期服务时间"
        required
        :error="$et($v.form.reservationServiceTime)"
      >
        <el-date-picker
          class="form-input-item"
          v-model="form.reservationServiceTime"
          type="datetime"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="老人姓名"
        required
        :error="$et($v.form.elderName)"
      >
        <el-input
          class="form-input-item"
          v-model="form.elderName"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="性别"
        required
        :error="$et($v.form.gender)"
      >
        <el-select
          class="form-input-item"
          v-model="form.gender"
        >
          <el-option value="男"></el-option>
          <el-option value="女"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="联系方式"
        required
        :error="$et($v.form.phone)"
      >
        <el-input
          class="form-input-item"
          v-model="form.phone"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="老人住址"
        required
        :error="$et($v.form.address)"
      >
        <el-input
          class="form-input-item"
          v-model="form.address"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button
        size="small"
        @click="onCancelClick"
      >取消</el-button>
      <el-button
        :loading="loading"
        size="small"
        type="primary"
        @click="onOKClick"
      >确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/plugins/error-handler';
import { initiateServiceAppointment } from '@/api/org-reservation-service​';

export default {
  props: {
    title: {
      default: '预约服务',
    },
    serviceOrgItemId: {
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,

      loading: false,

      form: {
        reservationContent: '',
        reservationServiceTime: '',
        elderName: '',
        gender: '',
        phone: '',
        address: '',
      },
    };
  },
  validations: {
    form: {
      reservationContent: {
        required,
      },
      reservationServiceTime: {
        required,
      },
      elderName: {
        required,
      },
      gender: {
        required,
      },
      phone: {
        required,
      },
      address: {
        required,
      },
    },
  },
  errors: {
    required: '该项不能为空',
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },

    onDialogHide() {
      this.$emit('hide');
    },

    async onOKClick() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.loading = true;
        const params = {
          serviceOrgItemId: this.serviceOrgItemId,
          ...this.form,
        };
        await initiateServiceAppointment(params);
        this.$message.success('发起成功');
        this.$emit('ok');

        this.hide();
      } catch (error) {
        console.error(error);
        errorHandler('发起预约失败', error);
      } finally {
        this.loading = false;
      }
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>

<style>
</style>
