import { request } from '@/utils/request';

// 获取部门树
export function getDepartmentTree() {
  return request({
    url: '/department/',
    method: 'GET',
  });
}

// 创建部门
export function createDepartment(parameters) {
  return request({
    url: '/department/',
    method: 'POST',
    data: parameters,
  });
}

// 修改部门名称
export function editDepartmemtName(parameters) {
  return request({
    url: `/department/${parameters.id}/name`,
    method: 'PUT',
    data: parameters.data,
  });
}

// 删除部门
export function deleteDepartment(id) {
  return request({
    url: `/department/${id}`,
    method: 'DELETE',
  });
}

// 移动部门
export function moveDepartment(departmentId, parentId) {
  return request({
    url: `/department/${departmentId}/move/${parentId}`,
    method: 'PUT',
  });
}

// 获取部门详情
export function getDepartmentDetail(id) {
  return request({
    url: `/department/${id}`,
    method: 'GET',
  });
}

// 获取部门职务
export function getDepartmentPosition(id) {
  return request({
    url: `/department/${id}/position`,
    method: 'GET',
  });
}

// 添加部门职位
export function addDepartmentPosition(parameters) {
  return request({
    url: `/department/${parameters.id}/position`,
    method: 'POST',
    data: parameters.data,
  });
}

// 编辑部门职位
export function editDepartmentPosition(parameters) {
  return request({
    url: `/department/position/${parameters.id}`,
    method: 'PUT',
    data: parameters.data,
  });
}

// 删除部门职位
export function deleteDepartmentPosition(id) {
  return request({
    url: `/department/position/${id}`,
    method: 'DELETE',
  });
}

// 编辑部门负责人
export function editDepartmemtLeader(parameters) {
  return request({
    url: `/department/${parameters.id}/director`,
    method: 'PUT',
    data: parameters.data,
  });
}
