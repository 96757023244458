import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import medicationManagementActiveIcon from '@/assets/menu/medication-management-active-icon.svg';
import medicationManagementInactiveIcon from '@/assets/menu/medication-management-inactive-icon.svg';

const routes = [
  {
    path: '/medication-management',
    name: 'MedicationManagement',
    redirect: '/medication-management/medication-plan',
    meta: {
      menu: {
        name: '用药管理',
        icon: {
          active: medicationManagementActiveIcon,
          inactive: medicationManagementInactiveIcon,
        },
      },
      permissions: Ops.or([
        '用药计划',
        '用药信息',
        '用药记录',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/medication-management/medication-plan',
        name: 'MedicationPlan',
        meta: {
          menu: {
            name: '用药计划',
          },
          breadcrumb: {
            label: '用药计划',
          },
          permissions: '用药计划',
        },
        component: () => import('@/views/medication-management/medication-plan/index.vue'),
      },
      {
        path: '/medication-management/medication-plan/check/:id',
        name: 'MedicationPlanCheck',
        props: true,
        meta: {
          menu: {
            name: '查看用药计划',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MedicationPlan',
            label: '查看用药计划',
          },
          activeMenu: '/medication-management/medication-plan',
          permissions: '用药计划',
        },
        component: () => import('@/views/medication-management/medication-plan/check/index.vue'),
      },
      {
        path: '/medication-management/medication-plan/modify/:id',
        name: 'MedicationPlanModify',
        props: true,
        meta: {
          menu: {
            name: '更改用药计划',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MedicationPlan',
            label: '更改用药计划',
          },
          activeMenu: '/medication-management/medication-plan',
          permissions: '用药计划',
        },
        component: () => import('@/views/medication-management/medication-plan/modify/index.vue'),
      },
      {
        path: '/medication-management/medication-info',
        name: 'MedicationInfo',
        meta: {
          menu: {
            name: '用药信息',
          },
          breadcrumb: {
            label: '用药信息',
          },
          permissions: '用药信息',
        },
        component: () => import('@/views/medication-management/medication-info/index.vue'),
      },
      {
        path: '/medication-management/medication-info/elder-drugs-management',
        name: 'ElderDrugsManagement',
        meta: {
          menu: {
            name: '老年人药品管理',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MedicationInfo',
            label: '老年人药品管理',
          },
          activeMenu: '/medication-management/medication-info',
          permissions: '用药信息',
        },
        component: () => import('@/views/medication-management/medication-info/elder-drugs-management/index.vue'),
      },
      {
        path: '/medication-management/medication-info/elder-drugs-management/drug-inventory/:id',
        name: 'DrugInventory',
        props: true,
        meta: {
          menu: {
            name: '库存管理',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ElderDrugsManagement',
            label: '库存管理',
          },
          activeMenu: '/medication-management/medication-info',
          permissions: '用药信息',
        },
        component: () => import('@/views/medication-management/medication-info/elder-drugs-management/drug-inventory/index.vue'),
      },

      {
        path: '/medication-management/medication-info/elder-drugs-management/drug-inventory/:id/warehousing',
        name: 'DrugInventoryWarehousing',
        props: true,
        meta: {
          menu: {
            name: '入库记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DrugInventory',
            label: '入库记录',
          },
          activeMenu: '/medication-management/medication-info',
          permissions: '用药信息',
        },
        component: () => import('@/views/medication-management/medication-info/elder-drugs-management/drug-inventory/warehousing/index.vue'),
      },
      {
        path: '/medication-management/medication-info/elder-drugs-management/drug-inventory/:id/ex-warehouse',
        name: 'DrugInventoryExWarehouse',
        props: true,
        meta: {
          menu: {
            name: '出库记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DrugInventory',
            label: '出库记录',
          },
          activeMenu: '/medication-management/medication-info',
          permissions: '用药信息',
        },
        component: () => import('@/views/medication-management/medication-info/elder-drugs-management/drug-inventory/ex-warehouse/index.vue'),
      },
      {
        path: '/medication-management/medication-info/drug-handover/:id',
        name: 'DrugHandover',
        props: true,
        meta: {
          menu: {
            name: '药品交接',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MedicationInfo',
            label: '药品交接',
          },
          activeMenu: '/medication-management/medication-info',
          permissions: '用药信息',
        },
        component: () => import('@/views/medication-management/medication-info/drug-handover/index.vue'),
      },
      {
        path: '/medication-management/medication-info/drug-check/:id',
        name: 'DrugCheck',
        props: true,
        meta: {
          menu: {
            name: '核对用药',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MedicationInfo',
            label: '核对用药',
          },
          activeMenu: '/medication-management/medication-info',
          permissions: '用药信息',
        },
        component: () => import('@/views/medication-management/medication-info/drug-check/index.vue'),
      },
      {
        path: '/medication-management/medication-info/drug-consumption/:id',
        name: 'DrugConsumption',
        props: true,
        meta: {
          menu: {
            name: '药品消耗',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MedicationInfo',
            label: '药品消耗',
          },
          activeMenu: '/medication-management/medication-info',
        },
        component: () => import('@/views/medication-management/medication-info/drug-consumption/index.vue'),
      },
      {
        path: '/medication-management/medication-record',
        name: 'MedicationRecord',
        meta: {
          menu: {
            name: '用药记录',
          },
          breadcrumb: {
            label: '用药记录',
          },
          permissions: '用药记录',
        },
        component: () => import('@/views/medication-management/medication-record/index.vue'),
      },
      {
        path: '/medication-management/medication-record/handover-record/:id',
        name: 'HandoverRecord',
        props: true,
        meta: {
          menu: {
            name: '药品交接记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MedicationRecord',
            label: '药品交接记录',
          },
          activeMenu: '/medication-management/medication-record',
        },
        component: () => import('@/views/medication-management/medication-record/handover-record/index.vue'),
      },
      {
        path: '/medication-management/medication-record/handover-record/:id/detail/:recordId',
        name: 'HandoverRecordDetail',
        props: true,
        meta: {
          menu: {
            name: '交接记录详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'HandoverRecord',
            label: '交接记录详情',
          },
          activeMenu: '/medication-management/medication-record',
        },
        component: () => import('@/views/medication-management/medication-record/handover-record/detail/index.vue'),
      },

      {
        path: '/medication-management/medication-record/check-record/:id',
        name: 'CheckRecord',
        props: true,
        meta: {
          menu: {
            name: '核对药品记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MedicationRecord',
            label: '核对药品记录',
          },
          activeMenu: '/medication-management/medication-record',
        },
        component: () => import('@/views/medication-management/medication-record/check-record/index.vue'),
      },
      {
        path: '/medication-management/medication-record/check-record/:id/detail/:recordId',
        name: 'CheckRecordDetail',
        props: true,
        meta: {
          menu: {
            name: '核对记录详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'CheckRecord',
            label: '核对记录详情',
          },
          activeMenu: '/medication-management/medication-record',
        },
        component: () => import('@/views/medication-management/medication-record/check-record/detail/index.vue'),
      },

      {
        path: '/medication-management/medication-record/consume-record/:id',
        name: 'ConsumeRecord',
        props: true,
        meta: {
          menu: {
            name: '药品消耗记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MedicationRecord',
            label: '药品消耗记录',
          },
          activeMenu: '/medication-management/medication-record',
        },
        component: () => import('@/views/medication-management/medication-record/consume-record/index.vue'),
      },
      {
        path: '/medication-management/medication-record/consume-record/:id/detail/:recordId',
        name: 'ConsumeRecordDetail',
        props: true,
        meta: {
          menu: {
            name: '消耗记录详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ConsumeRecord',
            label: '消耗记录详情',
          },
          activeMenu: '/medication-management/medication-record',
        },
        component: () => import('@/views/medication-management/medication-record/consume-record/detail/index.vue'),
      },
    ],
  },
];

export default routes;
