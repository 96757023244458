import Vue from 'vue';
import VueRouter from 'vue-router';
import constantRoutes from './constant-route';
import dynamicRoutes from './dynamic-routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    ...constantRoutes,
    ...dynamicRoutes,
  ],
});

export default router;
