import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import seniorManagementActiveIcon from '@/assets/menu/senior-management-active-icon.svg';
import seniorManagementInactiveIcon from '@/assets/menu/senior-management-inactive-icon.svg';

const routes = [
  {
    path: '/senior-management',
    name: 'SeniorManagement',
    redirect: '/senior-management/upload-material-management',
    meta: {
      menu: {
        name: '高级管理',
        icon: {
          active: seniorManagementActiveIcon,
          inactive: seniorManagementInactiveIcon,
        },
      },
      permissions: Ops.or([
        '上传材料管理',
        '能力评估管理',
        '工作流程管理',
        '合同管理',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/senior-management/upload-material-management',
        name: 'UploadMaterialManagement',
        meta: {
          menu: {
            name: '上传材料管理',
          },
          breadcrumb: {
            label: '上传材料管理',
          },
          permissions: '上传材料管理',
        },
        component: () => import('@/views/senior-management/upload-material-management/index.vue'),
      },
      {
        path: '/senior-management/capacity-assessment-management',
        name: 'CapacityAssessmentManagement',
        meta: {
          menu: {
            name: '能力评估管理',
          },
          breadcrumb: {
            label: '能力评估管理',
          },
          permissions: '能力评估管理',
        },
        component: () => import('@/views/senior-management/capacity-assessment-management/index.vue'),
      },
      {
        path: '/senior-management/workflow-management',
        name: 'WorkflowManagement',
        meta: {
          menu: {
            name: '工作流程管理',
          },
          breadcrumb: {
            label: '工作流程管理',
          },
          permissions: '工作流程管理',
        },
        component: () => import('@/views/senior-management/workflow-management/index.vue'),
      },
      {
        path: '/senior-management/workflow-management/edit/:id',
        name: 'WorkflowManagementEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑工作流程',
            hidden: true,
          },
          breadcrumb: {
            parent: 'WorkflowManagement',
            label: '编辑工作流程',
          },
          activeMenu: '/senior-management/workflow-management',
          permissions: '工作流程管理',
        },
        component: () => import('@/views/senior-management/workflow-management/edit/index.vue'),
      },
      {
        path: '/senior-management/workflow-management/edit-out/:id',
        name: 'WorkflowManagementEditOut',
        props: true,
        meta: {
          menu: {
            name: '编辑工作流程',
            hidden: true,
          },
          breadcrumb: {
            parent: 'WorkflowManagement',
            label: '编辑工作流程',
          },
          activeMenu: '/senior-management/workflow-management',
          permissions: '工作流程管理',
        },
        component: () => import('@/views/senior-management/workflow-management/edit-out/index.vue'),
      },
      {
        path: '/senior-management/workflow-management/detail/:id',
        name: 'WorkflowManagementDetail',
        props: true,
        meta: {
          menu: {
            name: '查看工作流程',
            hidden: true,
          },
          breadcrumb: {
            parent: 'WorkflowManagement',
            label: '查看工作流程',
          },
          activeMenu: '/senior-management/workflow-management',
          permissions: '工作流程管理',
        },
        component: () => import('@/views/senior-management/workflow-management/detail/index.vue'),
      },
      {
        path: '/senior-management/contract-management',
        name: 'ContractManagement',
        meta: {
          menu: {
            name: '合同管理',
          },
          breadcrumb: {
            label: '合同管理',
          },
          permissions: '合同管理',
        },
        component: () => import('@/views/senior-management/contract-management/index.vue'),
      },
      {
        path: '/senior-management/contract-management/detail/:id',
        name: 'ContractManagementDetail',
        props: true,
        meta: {
          menu: {
            name: '合同详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ContractManagement',
            label: '合同详情',
          },
          activeMenu: '/senior-management/contract-management',
          permissions: '合同管理',
        },
        component: () => import('@/views/senior-management/contract-management/detail/index.vue'),
      },
    ],
  },
];

export default routes;
