import { request } from '@/utils/request';

// 获取部门下员工
export function getDepartmentStaff(parameters) {
  return request({
    url: `/staff/department/${parameters.id}`,
    method: 'GET',
    params: parameters.params,
  });
}

// 添加员工
export function addDepartmentStaff(parameters) {
  return request({
    url: `/staff/department/${parameters.id}`,
    method: 'POST',
    data: parameters.data,
  });
}

// 根据手机号获取员工
export function testStaff(parameters) {
  return request({
    url: '/staff/telephone',
    method: 'GET',
    params: parameters,
  });
}

// 获取员工信息
export function getDepartmentStaffDetail(staffId) {
  return request({
    url: `/staff/${staffId}/detail`,
    method: 'GET',
  });
}

// 编辑员工信息
export function editDepartmentStaff(parameters) {
  return request({
    url: `/staff/${parameters.staffId}`,
    method: 'PUT',
    data: parameters.data,
  });
}

// 重置密码
export function resetStaffPassword(id) {
  return request({
    url: `/staff/${id}/reset`,
    method: 'PUT',
  });
}

// 修改员工冻结状态
export function editStaffLockStatus(parameters) {
  return request({
    url: `/staff/${parameters.id}/lock`,
    method: 'PUT',
    data: parameters.data,
  });
}

// 删除员工(针对一个部门)
export function deleteStaffLockStatus(parameters) {
  return request({
    url: `/department/${parameters.departmentId}/staff/${parameters.staffId}`,
    method: 'DELETE',
  });
}
// 删除员工(针对员工所在所有部门)
export function deleteStaffFromAllDepartments(parameters) {
  return request({
    url: `/department/staff/${parameters.staffId}`,
    method: 'DELETE',
  });
}

// 根据账号获取员工
export function testStaffAccount(parameters) {
  return request({
    url: '/staff/account',
    method: 'GET',
    params: parameters,
  });
}
