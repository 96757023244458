import Layout from '@/views/layout/index.vue';

const routes = [
  {
    path: '/personal-center',
    name: 'PersonalCenter',
    redirect: '/personal-center/info',
    meta: {
      menu: {
        name: '个人中心',
        hidden: true,
      },
    },
    component: Layout,
    children: [
      {
        path: '/personal-center/info',
        name: 'PersonalInfo',
        meta: {
          menu: {
            name: '个人中心',
          },
          breadcrumb: {
            label: '个人中心',
          },
        },
        component: () => import('@/views/personal-center/index.vue'),
      },
    ],
  },
];

export default routes;
