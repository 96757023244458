import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import storageManagementActiveIcon from '@/assets/menu/storage-management-active-icon.svg';
import storageManagementInactiveIcon from '@/assets/menu/storage-management-inactive-icon.svg';

const routes = [
  {
    path: '/storage-management',
    name: 'StorageManagement',
    redirect: '/storage-management/accessories-type',
    meta: {
      menu: {
        name: '仓储管理',
        icon: {
          active: storageManagementActiveIcon,
          inactive: storageManagementInactiveIcon,
        },
      },
      permissions: Ops.or([
        '辅具类型管理',
        '辅具产品管理',
        '辅具库存管理',
        '后勤材料管理',
        '后勤材料库存管理',
        '固定资产类型',
        '固定资产管理',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/storage-management/accessories-type',
        name: 'AccessoriesType',
        meta: {
          menu: {
            name: '辅具类型管理',
          },
          breadcrumb: {
            label: '辅具类型管理',
          },
          permissions: '辅具类型管理',
        },
        component: () => import('@/views/storage-management/accessories-type/index.vue'),
      },

      {
        path: '/storage-management/accessories-product',
        name: 'AccessoriesProduct',
        meta: {
          menu: {
            name: '辅具产品管理',
          },
          breadcrumb: {
            label: '辅具产品管理',
          },
          permissions: '辅具产品管理',
        },
        component: () => import('@/views/storage-management/accessories-product/index.vue'),
      },
      {
        path: '/storage-management/accessories-product/add',
        name: 'AddAccessoriesProduct',
        meta: {
          menu: {
            name: '添加辅具产品',
            hidden: true,
          },
          breadcrumb: {
            label: '添加辅具产品',
            parent: 'AccessoriesProduct',
          },
          permissions: '辅具产品管理',
          activeMenu: '/storage-management/accessories-product',
        },
        component: () => import('@/views/storage-management/accessories-product/add/index.vue'),
      },
      {
        path: '/storage-management/accessories-product/edit/:id',
        name: 'EditAccessoriesProduct',
        props: true,
        meta: {
          menu: {
            name: '编辑辅具产品',
            hidden: true,
          },
          breadcrumb: {
            label: '编辑辅具产品',
            parent: 'AccessoriesProduct',
          },
          permissions: '辅具产品管理',
          activeMenu: '/storage-management/accessories-product',
        },
        component: () => import('@/views/storage-management/accessories-product/edit/index.vue'),
      },
      {
        path: '/storage-management/accessories-product/detail/:id',
        name: 'ViewAccessoriesProduct',
        props: true,
        meta: {
          menu: {
            name: '查看辅具产品',
            hidden: true,
          },
          breadcrumb: {
            label: '查看辅具产品',
            parent: 'AccessoriesProduct',
          },
          permissions: '辅具产品管理',
          activeMenu: '/storage-management/accessories-product',
        },
        component: () => import('@/views/storage-management/accessories-product/detail/index.vue'),
      },
      {
        path: '/storage-management/accessories-stock',
        name: 'AccessoriesStock',
        meta: {
          menu: {
            name: '辅具库存管理',
          },
          breadcrumb: {
            label: '辅具库存管理',
          },
          permissions: '辅具库存管理',
        },
        component: () => import('@/views/storage-management/accessories-stock/index.vue'),
      },
      {
        path: '/storage-management/accessories-stock/detail/:id',
        name: 'AccessoriesStockDetail',
        props: true,
        meta: {
          menu: {
            name: '辅具库存详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AccessoriesStock',
            label: '辅具库存详情',
          },
          permissions: '辅具库存管理',
        },
        component: () => import('@/views/storage-management/accessories-stock/detail/index.vue'),
      },
      {
        path: '/storage-management/accessories-stock/lend/:id',
        name: 'AccessoriesStockLend',
        props: true,
        meta: {
          menu: {
            name: '辅具库存借用',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AccessoriesStock',
            label: '辅具库存借用',
          },
          activeMenu: '/storage-management/accessories-stock',
          permissions: '辅具库存管理',
        },
        component: () => import('@/views/storage-management/accessories-stock/lend/index.vue'),
      },
      {
        path: '/storage-management/accessories-stock/receive/:id',
        name: 'AccessoriesStockReceive',
        props: true,
        meta: {
          menu: {
            name: '辅具库存领取',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AccessoriesStock',
            label: '辅具库存领取',
          },
          activeMenu: '/storage-management/accessories-stock',
          permissions: '辅具库存管理',
        },
        component: () => import('@/views/storage-management/accessories-stock/receive/index.vue'),
      },
      {
        path: '/storage-management/accessories-stock/operation-record',
        name: 'AccessoriesStockOperationRecord',
        props: true,
        meta: {
          menu: {
            name: '辅具库存操作记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AccessoriesStock',
            label: '辅具库存操作记录',
          },
          activeMenu: '/storage-management/accessories-stock',
          permissions: '辅具库存管理',
        },
        component: () => import('@/views/storage-management/accessories-stock/operation-record/index.vue'),
      },
      {
        path: '/storage-management/logistics-materials',
        name: 'LogisticsMaterials',
        meta: {
          menu: {
            name: '后勤材料管理',
          },
          breadcrumb: {
            label: '后勤材料管理',
          },
          permissions: '后勤材料管理',
        },
        component: () => import('@/views/storage-management/logistics-materials/index.vue'),
      },
      {
        path: '/storage-management/logistics-materials-stock',
        name: 'LogisticsMaterialsStock',
        meta: {
          menu: {
            name: '后勤材料库存管理',
          },
          breadcrumb: {
            label: '后勤材料库存管理',
          },
          permissions: '后勤材料库存管理',
        },
        component: () => import('@/views/storage-management/logistics-materials-stock/index.vue'),
      },
      {
        path: '/storage-management/logistics-materials-stock/receive/:id',
        name: 'LogisticsMaterialsStockReceive',
        props: true,
        meta: {
          menu: {
            name: '领取记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'LogisticsMaterialsStock',
            label: '领取记录',
          },
          activeMenu: '/storage-management/logistics-materials-stock',
          permissions: '后勤材料库存管理',
        },
        component: () => import('@/views/storage-management/logistics-materials-stock/receive/index.vue'),
      },
      {
        path: '/storage-management/logistics-materials-stock/operation-record',
        name: 'LogisticsMaterialsStockOperationRecord',
        props: true,
        meta: {
          menu: {
            name: '后勤材料领取记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'LogisticsMaterialsStock',
            label: '后勤材料领取记录',
          },
          activeMenu: '/storage-management/logistics-materials-stock',
          permissions: '后勤材料库存管理',
        },
        component: () => import('@/views/storage-management/logistics-materials-stock/operation-record/index.vue'),
      },
      {
        path: '/storage-management/fixed-assets-type',
        name: 'FixedAssetsType',
        meta: {
          menu: {
            name: '固定资产类型',
          },
          breadcrumb: {
            label: '固定资产类型',
          },
          permissions: '固定资产类型',
        },
        component: () => import('@/views/storage-management/fixed-assets-type/index.vue'),
      },
      {
        path: '/storage-management/fixed-assets',
        name: 'FixedAssets',
        meta: {
          menu: {
            name: '固定资产管理',
          },
          breadcrumb: {
            label: '固定资产管理',
          },
          permissions: '固定资产管理',
        },
        component: () => import('@/views/storage-management/fixed-assets/index.vue'),
      },
      {
        path: '/storage-management/fixed-assets/lend/:id',
        name: 'FixedAssetsLend',
        props: true,
        meta: {
          menu: {
            name: '辅具库存借用',
            hidden: true,
          },
          breadcrumb: {
            parent: 'FixedAssets',
            label: '辅具库存借用',
          },
          activeMenu: '/storage-management/fixed-assets',
          permissions: '辅具库存管理',
        },
        component: () => import('@/views/storage-management/fixed-assets/lend/index.vue'),
      },
      {
        path: '/storage-management/fixed-assets/receive/:id',
        name: 'FixedAssetsReceive',
        props: true,
        meta: {
          menu: {
            name: '辅具库存领取',
            hidden: true,
          },
          breadcrumb: {
            parent: 'FixedAssets',
            label: '辅具库存领取',
          },
          activeMenu: '/storage-management/fixed-assets',
          permissions: '辅具库存管理',
        },
        component: () => import('@/views/storage-management/fixed-assets/receive/index.vue'),
      },
      {
        path: '/storage-management/fixed-assets/operation-record',
        name: 'FixedAssetsOperationRecord',
        props: true,
        meta: {
          menu: {
            name: '固定资产记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'FixedAssets',
            label: '固定资产记录',
          },
          activeMenu: '/storage-management/fixed-assets',
          permissions: '固定资产记录',
        },
        component: () => import('@/views/storage-management/fixed-assets/operation-record/index.vue'),
      },

    ],
  },
];

export default routes;
