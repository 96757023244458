import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import recruitCenterActiveIcon from '@/assets/menu/recruit-center-active-icon.svg';
import recruitCenterInactiveIcon from '@/assets/menu/recruit-center-inactive-icon.svg';

const routes = [
  {
    path: '/roster',
    name: 'Roster',
    meta: {
      menu: {
        name: '员工花名册',
        icon: {
          active: recruitCenterActiveIcon,
          inactive: recruitCenterInactiveIcon,
        },
      },
      permissions: Ops.or([
        '花名册',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/roster/list',
        name: 'RosterList',
        meta: {
          menu: {
            name: '花名册',
          },
          breadcrumb: {
            label: '花名册',
          },
          permissions: '花名册',
        },
        component: () => import('@/views/roster/index.vue'),
      },
      {
        path: '/roster/list/add',
        name: 'RosterListAdd',
        meta: {
          menu: {
            name: '添加员工',
            hidden: true,
          },
          breadcrumb: {
            label: '添加员工',
            parent: 'RosterList',
          },
          activeMenu: '/roster/list',
          permissions: '花名册',
        },
        component: () => import('@/views/roster/add/index.vue'),
      },
      {
        path: '/roster/list/edit/:staffId',
        name: 'RosterStaffEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑员工',
            hidden: true,
          },
          breadcrumb: {
            label: '编辑员工',
            parent: 'RosterList',
          },
          activeMenu: '/roster/list',
          permissions: '花名册',
        },
        component: () => import('@/views/roster/edit/index.vue'),
      },
      {
        path: '/roster/list/leave/:staffId',
        name: 'RosterStaffLeave',
        props: true,
        meta: {
          menu: {
            name: '办理离职',
            hidden: true,
          },
          breadcrumb: {
            label: '办理离职',
            parent: 'RosterList',
          },
          activeMenu: '/roster/list',
          permissions: '花名册',
        },
        component: () => import('@/views/roster/staff-leave/handle-leave.vue'),
      },
      {
        path: '/roster/list/change-leave-info/:recordId',
        name: 'RosterChangeLeaveInfo',
        props: true,
        meta: {
          menu: {
            name: '修改离职信息',
            hidden: true,
          },
          breadcrumb: {
            label: '修改离职信息',
            parent: 'RosterList',
          },
          activeMenu: '/roster/list',
          permissions: '花名册',
        },
        component: () => import('@/views/roster/staff-leave/change-leave-info.vue'),
      },
      {
        path: '/roster/list/re-check-in/:staffId/:recordId',
        name: 'ReCheckIn',
        props: true,
        meta: {
          menu: {
            name: '重新入职',
            hidden: true,
          },
          breadcrumb: {
            label: '重新入职',
            parent: 'RosterList',
          },
          activeMenu: '/roster/list',
          permissions: '花名册',
        },
        component: () => import('@/views/roster/staff-leave/re-entry/index.vue'),
      },
    ],
  },
];

export default routes;
