import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import recruitCenterActiveIcon from '@/assets/menu/recruit-center-active-icon.svg';
import recruitCenterInactiveIcon from '@/assets/menu/recruit-center-inactive-icon.svg';

const routes = [
  {
    path: '/recruit-center',
    name: 'Recruit',
    meta: {
      menu: {
        name: '招聘中心',
        icon: {
          active: recruitCenterActiveIcon,
          inactive: recruitCenterInactiveIcon,
        },
      },
      permissions: Ops.or([
        '招聘管理',
        '人才录用',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/recruit-center/recruit-manage',
        name: 'recruitManage',
        meta: {
          menu: {
            name: '招聘管理',
          },
          breadcrumb: {
            label: '招聘管理',
          },
          permissions: '招聘管理',
        },
        component: () => import('@/views/recruit-center/recruit-manage/index.vue'),
      },
      {
        path: '/recruit-center/recruit-manage/recruit-release',
        name: 'recruitRelease',
        meta: {
          menu: {
            name: '发起招聘',
            hidden: true,
          },
          breadcrumb: {
            label: '发起招聘',
            parent: 'recruitManage',
          },
          activeMenu: '/recruit-center/recruit-manage',
          permissions: '招聘管理',
        },
        component: () => import('@/views/recruit-center/recruit-manage/recruit-release/index.vue'),
      },
      {
        path: '/recruit-center/recruit-manage/recruit-edit/:id',
        name: 'recruitEdit',
        props: true,
        meta: {
          menu: {
            name: '岗位编辑',
            hidden: true,
          },
          breadcrumb: {
            label: '岗位编辑',
            parent: 'recruitManage',
          },
          activeMenu: '/recruit-center/recruit-manage',
          permissions: '招聘管理',
        },
        component: () => import('@/views/recruit-center/recruit-manage/recruit-edit/index.vue'),
      },
      {
        path: '/recruit-center/recruit-manage/recruit-detail/:id',
        props: true,
        name: 'recruitDetail',
        meta: {
          menu: {
            name: '职位详情',
            hidden: true,
          },
          breadcrumb: {
            label: '职位详情',
            parent: 'recruitManage',
          },
          activeMenu: '/recruit-center/recruit-manage',
          permissions: '招聘管理',
        },
        component: () => import('@/views/recruit-center/recruit-manage/recruit-detail/index.vue'),
      },
      {
        path: '/recruit-center/recruit-manage/recruit-detail/:id/recruit-check/:candidateId',
        name: 'recruitSee',
        props: true,
        meta: {
          menu: {
            name: '查看简历',
            hidden: true,
          },
          breadcrumb: {
            label: '查看简历',
            parent: 'recruitDetail',
          },
          activeMenu: '/recruit-center/recruit-manage',
          permissions: '招聘管理',
        },
        component: () => import('@/views/recruit-center/recruit-manage/recruit-detail/recruit-see.vue'),
      },
      {
        path: '/recruit-center/recruit-talent',
        name: 'recruitTalent',
        meta: {
          menu: {
            name: '人才录用',
          },
          breadcrumb: {
            label: '人才录用',
          },
          permissions: '人才录用',
        },
        component: () => import('@/views/recruit-center/recruit-talent/index.vue'),
      },
      {
        path: '/recruit-center/recruit-talent/recruit-check/:candidateId',
        name: 'talentRecruitSee',
        props: true,
        meta: {
          menu: {
            name: '查看简历',
            hidden: true,
          },
          breadcrumb: {
            label: '查看简历',
            parent: 'recruitTalent',
          },
          activeMenu: '/recruit-center/recruit-talent',
          permissions: '人才录用',
        },
        component: () => import('@/views/recruit-center/recruit-manage/recruit-detail/recruit-see.vue'),
      },
      {
        path: '/recruit-center/recruit-talent/recruit-employed/:talentId',
        name: 'recruitEmployed',
        props: true,
        meta: {
          menu: {
            name: '发起录用',
            hidden: true,
          },
          breadcrumb: {
            label: '发起录用',
            parent: 'recruitTalent',
          },
          activeMenu: '/recruit-center/recruit-talent',
          permissions: '人才录用',
        },
        component: () => import('@/views/recruit-center/recruit-talent/launch-employment/index.vue'),
      },
      {
        path: '/recruit-center/recruit-talent/recruit-employed/:talentId/edit-staff-info/:departmentId',
        name: 'staffInfoEdit',
        props: true,
        meta: {
          menu: {
            name: '补全信息',
            hidden: true,
          },
          breadcrumb: {
            label: '补全信息',
            parent: 'recruitEmployed',
          },
          activeMenu: '/recruit-center/recruit-talent',
          permissions: '人才录用',
        },
        component: () => import('@/views/recruit-center/recruit-talent/launch-employment/add/index.vue'),
      },
    ],
  },
];

export default routes;
