<script>
import { DataTablesServer } from 'vue-data-tables';

function samePagination(oldPag, newPag) {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in newPag) {
    if (newPag[prop] !== oldPag[prop]) {
      return false;
    }
  }
  return true;
}

export default {
  extends: DataTablesServer,
  props: {
    tableProps: {
      default() {
        return {
          headerCellStyle: {
            background: '#FAF5F6',
          },
        };
      },
    },
    paginationProps: {
      default() {
        return {
          pageSizes: [20, 30, 40, 50, 100],
        };
      },
    },
  },
  created() {
    this.innerTotal = this.total;
  },
  computed: {
    computedPagination() {
      return {
        page: this.innerCurrentPage,
        pageSize: this.innerPageSize,
      };
    },
  },
  methods: {
    setPagination(newPagination, forceServerRequest = false) {
      if (samePagination(this.computedPagination, newPagination)) {
        // eslint-disable-next-line no-underscore-dangle
        if (this._server && forceServerRequest === true) {
          this.queryChange('page');
        }
        return;
      }
      if ('page' in newPagination) {
        this.innerCurrentPage = newPagination.page;
      }
      if ('pageSize' in newPagination) {
        this.handleSizeChange(newPagination.pageSize);
      }
    },
    getCurrentSelection() {
      return this.$refs.elTable.store.states.selection.slice();
    },
  },
};
</script>

<style lang="scss">
.sc-table {
  .pagination-bar {
    margin-top: 12px;
  }
}
</style>
