import router from '@/router';
import store from '@/store';
import { generateRoutes } from './routes';

const whiteList = [
  '/login',
  '/login-safe',
  '/login-2fa',
  '/forget-password',
  '/ca-login',
  '/ca-select-org',
];

router.beforeEach(async (to, from, next) => {
  const { token } = store.getters;
  if (token) {
    if (
      to.path === '/login-safe'
      || to.path === '/ca-login'
      || to.path === '/login-2fa'
      || to.path === '/login'
    ) {
      next({ path: '/homepage' });
      return;
    }
    if (to.path === '/ca-select-org') {
      next();
      return;
    }
    if (!store.getters.account) {
      try {
        await store.dispatch('GET_MESSAGE_NUMBER');
        await store.dispatch('GET_INFO');
        const accessableRoutes = generateRoutes({
          permissions: store.getters.permissions,
          orgType: store.getters.orgType,
        });
        store.commit('SET_DYNAMIC_ROUTES', accessableRoutes);
        next({ ...to, replace: true });
        return;
      } catch (error) {
        await store.dispatch('FED_LOGOUT');
        next({ path: '/homepage' });
        return;
      }
    }

    if (store.getters.needChangePwd) {
      if (to.path === '/need-change-password') {
        next();
      } else {
        next({ path: '/need-change-password', replace: true });
      }
      return;
    }

    if (to.path === '/need-change-password') {
      next({ path: '/' });
    }

    next();
    return;
  }
  if (whiteList.includes(to.path)) {
    next();
    return;
  }
  next('/login');
  // next('/login-safe');
});
