import qs from 'qs';
import axios from 'axios';
import config from '@/config';

export const request = axios.create({
  baseURL: config.apiBaseUrl,
  paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'brackets',
    });
  },
  // 请求超时时间
  timeout: 20000,
});
