const state = {
  depositReturn: {
    type: '',
    deposit: '',
  },
  depositPay: {
    type: '',
    pay: '',
  },

  currentCheckInTab: '',
};

export default state;
