import { setSessionToken } from '@/utils/local-store-token';

export function SET_TOKEN(state, token) {
  state.token = token;
  setSessionToken(token);
}
export function SET_ACCOUNT(state, account) {
  state.account = account;
}
export function SET_NAME(state, name) {
  state.name = name;
}

export function SET_ROLES(state, roles) {
  state.roles = roles;
}

export function SET_POSITIONLIST(state, positionList) {
  state.positionList = positionList;
}

export function SET_MENU_LIST(state, menuList) {
  state.menuList = menuList;
}

export function SET_PERMISSIONS(state, permissions) {
  state.permissions = permissions;
}

export function SET_UNREADMESSAGE(state, unreadMessage) {
  state.unreadMessage = unreadMessage;
}

export function SET_ORG_NAME(state, orgName) {
  state.orgName = orgName;
}

export function SET_ORG_TYPE(state, organizationType) {
  state.organizationType = organizationType;
}

export function SET_NEED_CHANGE_PWD(state, needChangePwd) {
  state.needChangePwd = needChangePwd;
}
