import * as Ops from '@/plugins/v-permission/ops/index';
import Layout from '@/views/layout/index.vue';
import serviceRecordsActiveIcon from '@/assets/menu/service-records-active-icon.svg';
import serviceRecordsInactiveIcon from '@/assets/menu/service-records-inactive-icon.svg';

const routes = [
  {
    path: '/service-records',
    name: 'ServiceRecords',
    meta: {
      menu: {
        name: '服务记录',
        icon: {
          active: serviceRecordsActiveIcon,
          inactive: serviceRecordsInactiveIcon,
        },
      },
      permissions: Ops.or([
        '班组服务记录',
        '紧急工作记录',
        '个人服务记录',
        '临时工作记录',
        '员工服务记录',
      ]),
    },
    component: Layout,
    redirect: '/service-records/team',
    children: [
      {
        path: '/service-records/team',
        name: 'TeamServiceRecords',
        meta: {
          menu: {
            name: '班组服务记录',
          },
          breadcrumb: {
            label: '班组服务记录',
          },
          permissions: '班组服务记录',
        },
        component: () => import('@/views/service-records/team-service-records/index.vue'),
      },
      {
        path: '/service-records/team/service-history/:id',
        name: 'TeamServiceHistory',
        props: true,
        meta: {
          menu: {
            name: '服务记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'TeamServiceRecords',
            label: '服务记录',
          },
          permissions: '班组服务记录',
          activeMenu: '/service-records/team',
        },
        component: () => import('@/views/service-records/team-service-records/service-records/index.vue'),
      },
      {
        path: '/service-records/team/sort-team-history/:id',
        name: 'TeamSortTeamHistory',
        props: true,
        meta: {
          menu: {
            name: '排班记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'TeamServiceRecords',
            label: '排班记录',
          },
          permissions: '班组服务记录',
          activeMenu: '/service-records/team',
        },
        component: () => import('@/views/service-records/team-service-records/sort-team-records/index.vue'),
      },
      {
        path: '/service-records/staff',
        name: 'StaffServiceRecords',
        meta: {
          menu: {
            name: '员工服务记录',
          },
          breadcrumb: {
            label: '员工服务记录',
          },
          permissions: '员工服务记录',
        },
        component: () => import('@/views/service-records/staff-service-records/index.vue'),
      },
      {
        path: '/service-records/staff/service-history/:id',
        name: 'StaffServiceHistory',
        props: true,
        meta: {
          menu: {
            name: '服务记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'StaffServiceRecords',
            label: '服务记录',
          },
          permissions: '班组服务记录',
          activeMenu: '/service-records/staff',
        },
        component: () => import('@/views/service-records/staff-service-records/service-records/index.vue'),
      },
      {
        path: '/service-records/staff/sort-team-history/:id',
        name: 'StaffSortTeamHistory',
        props: true,
        meta: {
          menu: {
            name: '排班记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'StaffServiceRecords',
            label: '排班记录',
          },
          permissions: '班组服务记录',
          activeMenu: '/service-records/staff',
        },
        component: () => import('@/views/service-records/staff-service-records/sort-team-records/index.vue'),
      },
      {
        path: '/service-records/urgency',
        name: 'AllUrgencyWorkRecords',
        meta: {
          menu: {
            name: '紧急工作记录',
          },
          breadcrumb: {
            label: '紧急工作记录',
          },
          permissions: '紧急工作记录',
        },
        component: () => import('@/views/service-records/urgency-service-records/index.vue'),
      },
      {
        path: '/service-records/urgency/:id/detail',
        name: 'AllUrgencyWorkRecordsDetail',
        props: true,
        meta: {
          menu: {
            name: '紧急工作记录详情',
            hidden: true,
          },
          breadcrumb: {
            label: '紧急工作记录详情',
            parent: 'AllUrgencyWorkRecords',
          },
          permissions: '紧急工作记录',
        },
        component: () => import('@/views/service-records/urgency-service-records/details/index.vue'),
      },
      {
        path: '/service-records/temporary',
        name: 'TemporaryServiceRecords',
        meta: {
          menu: {
            name: '临时工作记录',
          },
          breadcrumb: {
            label: '临时工作记录',
          },
          permissions: '临时工作记录',
        },
        component: () => import('@/views/service-records/temporary-service-records/index.vue'),
      },
      {
        path: '/service-records/personal',
        name: 'PersonalServiceRecords',
        meta: {
          menu: {
            name: '个人服务记录',
          },
          breadcrumb: {
            label: '个人服务记录',
          },
          permissions: '个人服务记录',
        },
        component: () => import('@/views/service-records/personal-service-records/index.vue'),
      },
    ],
  },
];

export default routes;
