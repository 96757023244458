<template>
  <div class="appointment-record-detail app-container">
    <el-card shadow="never">
      <div class="progress">
        <div class="progress-item">
          <div class="progress-item-header progress-item-header-active">
            1.机构预约组织
          </div>
          <div class="progress-item-container">
            <el-form
              size="small"
              label-suffix=":"
              style="padding-left: 20px"
            >
              <el-form-item label="老人姓名">{{ data.elderName }}</el-form-item>
              <el-form-item label="性别">{{ data.gender }}</el-form-item>
              <el-form-item label="联系方式">{{ data.phone }}</el-form-item>
              <el-form-item label="老人住址">{{ data.address }}</el-form-item>
              <el-form-item label="服务项目">{{ data.serviceOrgItemName }}</el-form-item>
              <el-form-item label="服务内容">{{ data.reservationContent }}</el-form-item>
              <el-form-item label="服务时间">
                {{ formatterTime(data.reservationServiceTime) }}
              </el-form-item>
              <el-form-item label="预约组织">{{ data.reservationOrgName }}</el-form-item>
            </el-form>
          </div>
        </div>
        <div class="progress-item">
          <div class="progress-item-header progress-item-header-active">
            2.服务组织分配
          </div>
          <div class="progress-item-container">
            <el-form
              size="small"
              label-suffix=":"
              style="padding-left: 20px"
            >
              <el-form-item label="分配人员">
                {{ data.staffName }}
              </el-form-item>
              <el-form-item label="联系方式">{{ data.staffPhone }}</el-form-item>
              <el-form-item label="分配时间">{{ formatterTime(data.assignmentTime) }}</el-form-item>
            </el-form>
          </div>
        </div>
        <div class="progress-item">
          <div
            class="progress-item-header"
            :class="{'progress-item-header-active': data.isEvaluate}"
          >
            3.服务评价
          </div>
          <div class="progress-item-container">
            <el-form
              size="small"
              label-suffix=":"
              label-width="85px"
              style="padding-left: 6px"
            >
              <div v-if="!data.isEvaluate">
                <el-form-item
                  label="服务评价"
                  required
                  :error="$et($v.form.serviceEvaluate)"
                >
                  <el-radio-group v-model="form.serviceEvaluate">
                    <el-radio label="满意"></el-radio>
                    <el-radio label="一般"></el-radio>
                    <el-radio label="不满意"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input
                    v-model="form.remark"
                    style="width: 456px"
                    type="textarea"
                    :rows="5"
                  ></el-input>
                </el-form-item>
                <el-form-item label-width="0">
                  <el-button
                    :loading="loading"
                    type="primary"
                    @click="onSubmitClick"
                  >提交</el-button>
                  <el-button @click="onCancelClick">取消</el-button>
                </el-form-item>
              </div>
              <div v-else>
                <el-form-item label="服务评价">{{data.serviceEvaluate}}</el-form-item>
                <el-form-item label="备注">{{data.evaluateRemark || '暂无'}}</el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
  getOrgReservationServiceRecordDetail,
  submitEvaluate,
} from '@/api/org-reservation-service​';
import errorHandler from '@/plugins/error-handler';
import { formatterDateTime } from '@/utils/formatter-time';

export default {
  props: {
    recordId: {
      required: true,
    },
  },
  data() {
    return {
      data: {
        elderName: '',
        gender: '',
        phone: '',
        address: '',
        serviceOrgItemName: '',
        reservationServiceTime: '',
        reservationContent: '',
        reservationOrgName: '',
        status: '',
        staffName: '',
        staffPhone: '',
        assignmentTime: '',
        isEvaluate: '',
        serviceEvaluate: '',
      },

      loading: false,

      form: {
        serviceEvaluate: '',
        remark: '',
      },
    };
  },
  validations: {
    form: {
      serviceEvaluate: {
        required,
      },
    },
  },
  errors: {
    required: '该项不能为空',
  },
  created() {
    this.getOrgReservationServiceRecordDetail();
  },
  methods: {
    async getOrgReservationServiceRecordDetail() {
      try {
        const { data } = await getOrgReservationServiceRecordDetail(this.recordId);
        this.data = {
          ...this.data,
          ...data.data,
        };
      } catch (error) {
        errorHandler('获取记录详情失败', error);
      }
    },

    formatterTime(time) {
      if (time) {
        return formatterDateTime(time);
      }
      return '';
    },

    async onSubmitClick() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.loading = true;
        const params = {
          id: this.recordId,
          data: this.form,
        };
        await submitEvaluate(params);
        this.$message.success('评价成功');
        this.$router.back();
      } catch (error) {
        console.error(error);
        errorHandler('评价失败', error);
      } finally {
        this.loading = false;
      }
    },

    onCancelClick() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.appointment-record-detail {
  .progress-item-header {
    width: 100%;
    background: #f2f2f2;
    line-height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .progress-item-header-active {
    background: #fff8f9;
    color: #fa4f74;
  }
}
</style>
