import * as Ops from '@/plugins/v-permission/ops/index';
import Layout from '@/views/layout/index.vue';
import foodManageActiveIcon from '@/assets/menu/food-manage-active-icon.svg';
import foodManageInactiveIcon from '@/assets/menu/food-manage-inactive-icon.svg';

const routes = [
  {
    path: '/food-manage',
    name: 'FoodManage',
    meta: {
      menu: {
        name: '膳食管理',
        icon: {
          active: foodManageActiveIcon,
          inactive: foodManageInactiveIcon,
        },
      },
      permissions: Ops.or([
        '今日菜单',
        '菜单类型',
        '具体菜单',
        '老人菜单分配',
      ]),
    },
    component: Layout,
    redirect: '/food-manage/food-menu-type',
    children: [
      {
        path: '/food-manage/food-menu-type',
        name: 'FoodMenuType',
        meta: {
          menu: {
            name: '菜单类型',
          },
          breadcrumb: {
            label: '菜单类型',
          },
          permissions: '菜单类型',
        },
        component: () => import('@/views/food-manage/food-menu-type/index.vue'),
      },
      {
        path: '/food-manage/specific-food-menu',
        name: 'SpecificFoodMenu',
        meta: {
          menu: {
            name: '具体菜单',
          },
          breadcrumb: {
            label: '具体菜单',
          },
          permissions: '具体菜单',
        },
        component: () => import('@/views/food-manage/specific-food-menu/index.vue'),
      },
      {
        path: '/food-manage/specific-food-menu/add',
        name: 'AddSpecificFoodMenu',
        meta: {
          menu: {
            name: '添加菜单',
            hidden: true,
          },
          breadcrumb: {
            label: '添加菜单',
            parent: 'SpecificFoodMenu',
          },
          permissions: '具体菜单',
          activeMenu: '/food-manage/specific-food-menu',
        },
        component: () => import('@/views/food-manage/specific-food-menu/add/index.vue'),
      },
      {
        path: '/food-manage/specific-food-menu/:foodMenuId/edit',
        name: 'EditSpecificFoodMenu',
        props: true,
        meta: {
          menu: {
            name: '编辑菜单',
            hidden: true,
          },
          breadcrumb: {
            label: '编辑菜单',
            parent: 'SpecificFoodMenu',
          },
          permissions: '具体菜单',
          activeMenu: '/food-manage/specific-food-menu',
        },
        component: () => import('@/views/food-manage/specific-food-menu/edit/index.vue'),
      },
      {
        path: '/food-manage/specific-food-menu/:foodMenuId/view',
        name: 'ViewSpecificFoodMenu',
        props: true,
        meta: {
          menu: {
            name: '查看菜单详情',
            hidden: true,
          },
          breadcrumb: {
            label: '查看菜单详情',
            parent: 'SpecificFoodMenu',
          },
          permissions: '具体菜单',
          activeMenu: '/food-manage/specific-food-menu',
        },
        component: () => import('@/views/food-manage/specific-food-menu/view/index.vue'),
      },
      {
        path: '/food-manage/today-food-menu',
        name: 'TodayFoodMenu',
        meta: {
          menu: {
            name: '今日菜单',
          },
          breadcrumb: {
            label: '今日菜单',
          },
          permissions: '今日菜单',
        },
        component: () => import('@/views/food-manage/today-food-menu/index.vue'),
      },
      {
        path: '/food-manage/dispense-elder-menu',
        name: 'DispenseElderMenu',
        meta: {
          menu: {
            name: '老人菜单分配',
          },
          breadcrumb: {
            label: '老人菜单分配',
          },
          permissions: '老人菜单分配',

        },
        component: () => import('@/views/food-manage/food-menu-dispense/index.vue'),
      },
      {
        path: '/food-manage/dispense-elder-menu/:elderId/view',
        name: 'ViewDispenseElderMenu',
        props: true,
        meta: {
          menu: {
            name: '查看老人菜单分配',
            hidden: true,
          },
          breadcrumb: {
            label: '查看老人菜单分配',
            parent: 'DispenseElderMenu',
          },
          permissions: '老人菜单分配',
          activeMenu: '/food-manage/dispense-elder-menu',
        },
        component: () => import('@/views/food-manage/food-menu-dispense/view/index.vue'),
      },
    ],
  },
];

export default routes;
