import Layout from '@/views/layout/index.vue';
import homepageActiveIcon from '@/assets/menu/homepage-active-icon.svg';
import homepageInactiveIcon from '@/assets/menu/homepage-inactive-icon.svg';

const routes = [
  {
    path: '/homepage',
    name: 'Homepage',
    redirect: '/homepage/main',
    meta: {
      menu: {
        name: '首页',
        icon: {
          active: homepageActiveIcon,
          inactive: homepageInactiveIcon,
        },
      },
    },
    component: Layout,
    children: [
      {
        path: '/homepage/main',
        name: 'HomepageMain',
        meta: {
          menu: {
            name: '首页',

          },
          breadcrumb: {
            label: '首页',
          },
        },
        component: () => import('@/views/homepage/index.vue'),
      },
    ],
  },
];

export default routes;
