import { Message } from 'element-ui';

export default function errorHandler(message, error) {
  if (!error || !error.response) {
    Message({
      showClose: true,
      message,
      type: 'error',
    });
  } else {
    const { response } = error;
    const { data } = response;
    Message({
      message: `服务错误 ${data.message}`,
      type: 'error',
      showClose: true,
    });
  }
}
