export default {
  apiBaseUrl: '/inst/api/v1',
  bucketName: {
    portal: 'anhe-portal',
    inst: 'anhe-inst',
    supervise: 'anhe-care-supervise-public',
  },
  bucketUrl: {
    portal: 'https://znzh-oss.zwyun.anhetech.net/yanglao/',
    inst: 'https://znzh-oss.zwyun.anhetech.net/yanglao/',
    supervise: 'https://znzh-oss.zwyun.anhetech.net/yanglao/',
  },
  iframeBaseUrls: {
    solution: 'https://znzh.zwyun.bjtzh.gov.cn/solution-web/',
  },
};
