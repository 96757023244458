import Layout from '@/views/layout/index.vue';
import messageActiveIcon from '@/assets/menu/message-active-icon.svg';
import messageInactiveIcon from '@/assets/menu/message-inactive-icon.svg';

const routes = [
  {
    path: '/message-center',
    name: 'MessageCenter',
    redirect: '/message-center/main',
    meta: {
      menu: {
        name: '消息中心',
        icon: {
          active: messageActiveIcon,
          inactive: messageInactiveIcon,
        },
      },
    },
    component: Layout,
    children: [
      {
        path: '/message-center/main',
        name: 'MessageCenterMain',
        meta: {
          menu: {
            name: '消息中心',
          },
          breadcrumb: {
            label: '消息中心',
          },
        },
        component: () => import('@/views/message-center/index.vue'),
      },
    ],
  },
];

export default routes;
