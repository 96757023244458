<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggle-click="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <div class="menu-container">
        <a
          class="menu-item"
          :class="{selected: currentRouteName === 'PersonalInfo'}"
          @click="onPersonalCenterClick"
        >个人中心</a>
        <div>|</div>
        <a
          class="menu-item"
          :class="{selected: currentRouteName === 'MessageCenterMain'}"
          @click="onMessageCenterClick"
        >消息中心</a>
        <div>|</div>
        <a
          class="menu-item"
          :class="{selected: currentRouteName === 'TaskListManage'}"
          @click="taskManage"
        >任务管理</a>
        <div>|</div>
        <a
          class="menu-item"
          @click="logout"
        >注销登录</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/vue-breadcrumb/index.vue';
import Hamburger from '@/components/hamburger/index.vue';

export default {
  components: {
    Breadcrumb,
    Hamburger,
  },
  computed: {
    ...mapGetters(['sidebar', 'unreadMessage', 'name']),
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    onPersonalCenterClick() {
      this.$router
        .push({
          name: 'PersonalInfo',
        })
        .catch(() => {});
    },
    onMessageCenterClick() {
      this.$router
        .push({
          name: 'MessageCenter',
        })
        .catch(() => {});
    },
    taskManage() {
      this.$router
        .push({
          name: 'TaskListManage',
        })
        .catch(() => {});
    },
    async logout() {
      await this.$store.dispatch('LOGOUT');
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    .login-btn {
      margin-right: 50px;
    }

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        line-height: 1.5rem;

        .user-avatar {
          margin-top: 5px;
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.unread {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background: #f56c6c;
  color: #fff !important;
  text-align: center;
  font-size: 12px;
}
.menu-container {
  padding-right: 30px;
  display: flex;
  font-size: 16px;
}
.menu-item {
  padding: 0 5px;
}
.selected {
  color: #fa4f74;
}
</style>
