<template>
  <div class="service-project-management app-container">
    <el-card
      shadow="never"
      class="second-card-margin"
    >
      <el-form
        class="is-flex-between-wrap"
        size="small"
        label-width="69px"
      >
        <el-form-item label="服务项目">
          <el-input
            class="form-input-item"
            v-model="filterForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            @click="onSearchClick"
          >查询</el-button>
          <el-button @click="onResetClick">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="never">
      <el-button
        style="margin-bottom: 20px"
        type="primary"
        size="small"
        @click="onAddClick"
      >新建服务项目</el-button>
      <ZTable
        v-loading="loading"
        ref="table"
        :data="data"
        :current-page.sync="pagination.page"
        :page-size.sync="pagination.pageSize"
        :total="pagination.total"
        @query-change="loadData"
      >
        <el-table-column
          label="服务项目"
          prop="name"
        ></el-table-column>
        <el-table-column
          label="项目费用"
          :formatter="formatterFee"
        ></el-table-column>
        <el-table-column
          label="服务内容"
          prop="content"
        ></el-table-column>
        <el-table-column label="操作">
          <template v-slot="{row}">
            <el-button
              type="text"
              @click="onCheckClick(row)"
            >查看</el-button>
            <el-button
              type="text"
              @click="onEditClick(row)"
            >编辑</el-button>
            <el-button
              type="text"
              @click="onDeleteClick(row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </ZTable>
    </el-card>
  </div>
</template>

<script>
import ZTable from '@/components/z-table/index.vue';
import {
  getServiceOrgItemList,
  deleteServiceOrgItem,
} from '@/api/service-org';
import { getServerPageQuery } from '@/utils';
import errorHandler from '@/plugins/error-handler';
import AddDialog from './add-service-project.vue';
import CheckDialog from './check-service-project.vue';
import EditDialog from './edit-service-project.vue';

export default {
  name: 'ServiceProjectManagement',
  components: {
    ZTable,
  },
  data() {
    return {
      filterForm: {
        name: '',
      },

      pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
      },

      loading: false,

      data: [],
    };
  },
  methods: {
    async loadData(event) {
      try {
        this.loading = true;
        const { page, pageSize } = event;
        const params = {
          ...getServerPageQuery(page, pageSize),
        };
        if (this.filterForm.name) {
          params.name = this.filterForm.name;
        }
        const { data } = await getServiceOrgItemList(params);
        this.data = data.data.records;
        this.pagination.total = data.data.count;
      } catch (error) {
        console.error(error);
        errorHandler('获取列表失败', error);
      } finally {
        this.loading = false;
      }
    },

    formatterFee(row) {
      return `${row.fee}元/次`;
    },

    onAddClick() {
      this.$z.$dialog({
        parent: this,
        component: AddDialog,
      })
        .onOk(() => {
          this.$refs.table.setPagination({ page: 1 }, true);
        });
    },

    onCheckClick(row) {
      this.$z.$dialog({
        parent: this,
        component: CheckDialog,
        data: row,
      });
    },

    onEditClick(row) {
      this.$z.$dialog({
        parent: this,
        component: EditDialog,
        data: row,
      })
        .onOk(() => {
          const { page, pageSize } = this.pagination;
          this.$refs.table.setPagination({ page, pageSize }, true);
        });
    },

    onDeleteClick(row) {
      if (row.count > 0) {
        this.$confirm('有服务项目正在进行中，无法删除！', '删除', {
          type: 'warning',
        })
          .then(() => { })
          .catch(() => { });
      } else {
        this.$confirm('确定删除该服务项目吗?', '删除', {
          type: 'warning',
        })
          .then(() => {
            this.deleteServiceOrgItem(row.id);
          })
          .catch(() => { });
      }
    },

    async deleteServiceOrgItem(id) {
      try {
        await deleteServiceOrgItem(id);
        this.$message.success('删除成功');
        this.$refs.table.setPagination({ page: 1 }, true);
      } catch (error) {
        console.error(error);
        errorHandler('删除失败', error);
      }
    },

    onSearchClick() {
      this.$refs.table.setPagination({ page: 1 }, true);
    },

    onResetClick() {
      this.filterForm = {
        name: '',
      };
      this.$refs.table.setPagination({ page: 1, pageSize: 20 }, true);
    },
  },
};
</script>

<style>
</style>
