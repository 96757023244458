import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import institutionsActiceIcon from '@/assets/menu/institutions-actice-icon.svg';
import institutionsInacticeIcon from '@/assets/menu/institutions-inactice-icon.svg';

const routes = [
  {
    path: '/institutions',
    name: 'Institutions',
    redirect: '/institutions/building-info',
    meta: {
      menu: {
        name: '机构区域',
        icon: {
          active: institutionsActiceIcon,
          inactive: institutionsInacticeIcon,
        },
      },
      permissions: Ops.or([
        '楼宇信息',
        '区域管理',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/institutions/building-info',
        name: 'BuildingInfo',
        meta: {
          menu: {
            name: '楼宇信息',
          },
          breadcrumb: {
            label: '楼宇信息',
          },
          orgType: 'institutions',
          permissions: '楼宇信息',
        },
        component: () => import('@/views/institutions/building-info/index.vue'),
      },
      {
        path: '/institutions/building-info/:buildingId/floor-manage',
        name: 'FloorManage',
        props: true,
        meta: {
          menu: {
            name: '楼层管理',
            hidden: true,
          },
          breadcrumb: {
            label: '楼层管理',
            parent: 'BuildingInfo',
          },
          activeMenu: '/institutions/building-info',
          permissions: '楼宇信息',
        },
        component: () => import('@/views/institutions/building-info/floor-manage/index.vue'),
      },
      {
        path: '/institutions/building-info/:buildingId/floor-manage/:floorId/room-manage',
        name: 'RoomManage',
        props: true,
        meta: {
          menu: {
            name: '房间管理',
            hidden: true,
          },
          breadcrumb: {
            label: '房间管理',
            parent: 'FloorManage',
          },
          activeMenu: '/institutions/building-info',
          permissions: '楼宇信息',
        },
        component: () => import('@/views/institutions/building-info/floor-manage/room-manage/index.vue'),
      },
      {
        path: '/institutions/building-info/:buildingId/floor-manage/:floorId/room-manage/:roomId/bed-manage',
        name: 'bedManage',
        props: true,
        meta: {
          menu: {
            name: '床位管理',
            hidden: true,
          },
          breadcrumb: {
            label: '床位管理',
            parent: 'RoomManage',
          },
          activeMenu: '/institutions/building-info',
          permissions: '楼宇信息',
        },
        component: () => import('@/views/institutions/building-info/floor-manage/room-manage/bed-manage/index.vue'),
      },
      {
        path: '/institutions/area-manage',
        name: 'AreaManage',
        meta: {
          menu: {
            name: '区域管理',
          },
          breadcrumb: {
            label: '区域管理',
          },
          permissions: '区域管理',
        },
        component: () => import('@/views/institutions/area-manage/index.vue'),
      },
      {
        path: '/institutions/area-manage/:areaId/room-manage',
        name: 'AreaRoomManage',
        props: true,
        meta: {
          menu: {
            name: '房间管理',
            hidden: true,
          },
          breadcrumb: {
            label: '房间管理',
            parent: 'AreaManage',
          },
          activeMenu: '/institutions/area-manage',
          permissions: '区域管理',
        },
        component: () => import('@/views/institutions/area-manage/room-manage/index.vue'),
      },
    ],
  },
];

export default routes;
