import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import financeManagementActiveIcon from '@/assets/menu/finance-management-active-icon.svg';
import financeManagementInactiveIcon from '@/assets/menu/finance-management-inactive-icon.svg';

const routes = [
  {
    path: '/finance-management',
    name: 'FinanceManagement',
    redirect: '/finance-mangement/expense-type',
    meta: {
      menu: {
        name: '财务管理',
        icon: {
          active: financeManagementActiveIcon,
          inactive: financeManagementInactiveIcon,
        },
      },
      permissions: Ops.or([
        '费用类型',
        '收费标准',
        '押金管理',
        '费用设置',
        '月度缴费',
        '费用收支',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/finance-mangement/expense-type',
        name: 'ExpenseType',
        meta: {
          menu: {
            name: '费用类型',
          },
          breadcrumb: {
            label: '费用类型',
          },
          permissions: '费用类型',
        },
        component: () => import('@/views/finance-management/expense-type/index.vue'),
      },
      {
        path: '/finance-mangement/expense-type/periodicity',
        name: 'ExpenseTypePeriodicity',
        meta: {
          menu: {
            name: '周期性费用',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ExpenseType',
            label: '周期性费用',
          },
          activeMenu: '/finance-mangement/expense-type',
          permissions: '费用类型',
        },
        component: () => import('@/views/finance-management/expense-type/periodicity/index.vue'),
      },
      {
        path: '/finance-mangement/expense-type/disposable',
        name: 'ExpenseTypeDisposable',
        meta: {
          menu: {
            name: '一次性费用',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ExpenseType',
            label: '一次性费用',
          },
          activeMenu: '/finance-mangement/expense-type',
          permissions: '费用类型',
        },
        component: () => import('@/views/finance-management/expense-type/disposable/index.vue'),
      },
      {
        path: '/finance-mangement/expense-type/deposit',
        name: 'ExpenseTypeDeposit',
        meta: {
          menu: {
            name: '押金费用',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ExpenseType',
            label: '押金费用',
          },
          activeMenu: '/finance-mangement/expense-type',
          permissions: '费用类型',
        },
        component: () => import('@/views/finance-management/expense-type/deposit/index.vue'),
      },
      {
        path: '/finance-mangement/charging-standard',
        name: 'ChargingStandard',
        meta: {
          menu: {
            name: '收费标准',
          },
          breadcrumb: {
            label: '收费标准',
          },
          permissions: '收费标准',
        },
        component: () => import('@/views/finance-management/charging-standard/index.vue'),
      },
      {
        path: '/finance-mangement/charging-standard/create',
        name: 'ChargingStandardCreate',
        meta: {
          menu: {
            name: '创建收费标准',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ChargingStandard',
            label: '创建收费标准',
          },
          activeMenu: '/finance-mangement/charging-standard',
          permissions: '收费标准',
        },
        component: () => import('@/views/finance-management/charging-standard/create/index.vue'),
      },
      {
        path: '/finance-mangement/charging-standard/edit/:id',
        name: 'ChargingStandardEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑收费标准',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ChargingStandard',
            label: '编辑收费标准',
          },
          activeMenu: '/finance-mangement/charging-standard',
          permissions: '收费标准',
        },
        component: () => import('@/views/finance-management/charging-standard/edit/index.vue'),
      },
      {
        path: '/finance-mangement/deposit-management',
        name: 'DepositManagement',
        meta: {
          menu: {
            name: '押金管理',
          },
          breadcrumb: {
            label: '押金管理',
          },
          permissions: '押金管理',
        },
        component: () => import('@/views/finance-management/deposit-management/index.vue'),
      },
      {
        path: '/finance-mangement/deposit-management/detail/:elderId',
        name: 'DepositManagementDetail',
        props: true,
        meta: {
          menu: {
            name: '详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DepositManagement',
            label: '详情',
          },
          activeMenu: '/finance-mangement/deposit-management',
          permissions: '押金管理',
        },
        component: () => import('@/views/finance-management/deposit-management/detail/index.vue'),
      },
      {
        path: '/finance-mangement/deposit-management/detail/:elderId/pay',
        name: 'DepositManagementDetailPay',
        props: true,
        meta: {
          menu: {
            name: '押金缴纳',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DepositManagementDetail',
            label: '押金缴纳',
          },
          activeMenu: '/finance-mangement/deposit-management',
          permissions: '押金管理',
        },
        component: () => import('@/views/finance-management/deposit-management/detail/pay/index.vue'),
      },
      {
        path: '/finance-mangement/deposit-management/detail/:elderId/batch-pay',
        name: 'DepositManagementDetailBatchPay',
        props: true,
        meta: {
          menu: {
            name: '押金批量缴纳',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DepositManagementDetail',
            label: '押金批量缴纳',
          },
          activeMenu: '/finance-mangement/deposit-management',
          permissions: '押金管理',
        },
        component: () => import('@/views/finance-management/deposit-management/detail/batch-pay/index.vue'),
      },
      {
        path: '/finance-mangement/deposit-management/detail/:elderId/deduction/:depositId',
        name: 'DepositManagementDetailDeduction',
        props: true,
        meta: {
          menu: {
            name: '押金扣除',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DepositManagementDetail',
            label: '押金扣除',
          },
          activeMenu: '/finance-mangement/deposit-management',
          permissions: '押金管理',
        },
        component: () => import('@/views/finance-management/deposit-management/detail/deduction/index.vue'),
      },
      {
        path: '/finance-mangement/deposit-management/detail/:elderId/return/:depositId',
        name: 'DepositManagementDetailReturn',
        props: true,
        meta: {
          menu: {
            name: '押金退还',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DepositManagementDetail',
            label: '押金退还',
          },
          activeMenu: '/finance-mangement/deposit-management',
          permissions: '押金管理',
        },
        component: () => import('@/views/finance-management/deposit-management/detail/return/index.vue'),
      },
      {
        path: '/finance-mangement/deposit-management/detail/:elderId/batch-return',
        name: 'DepositManagementDetailBatchReturn',
        props: true,
        meta: {
          menu: {
            name: '押金批量退还',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DepositManagementDetail',
            label: '押金批量退还',
          },
          activeMenu: '/finance-mangement/deposit-management',
          permissions: '押金管理',
        },
        component: () => import('@/views/finance-management/deposit-management/detail/batch-return/index.vue'),
      },
      {
        path: '/finance-mangement/deposit-management/detail/:elderId/deduction-record',
        name: 'DeductionRecord',
        props: true,
        meta: {
          menu: {
            name: '扣除记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DepositManagementDetail',
            label: '扣除记录',
          },
          activeMenu: '/finance-mangement/deposit-management',
          permissions: '押金管理',
        },
        component: () => import('@/views/finance-management/deposit-management/detail/deduction-record/index.vue'),
      },
      {
        path: '/finance-mangement/expense-setting',
        name: 'ExpenseSetting',
        meta: {
          menu: {
            name: '费用设置',
          },
          breadcrumb: {
            label: '费用设置',
          },
          permissions: '费用设置',
        },
        component: () => import('@/views/finance-management/expense-setting/index.vue'),
      },
      {
        path: '/finance-mangement/expense-setting/edit/:id',
        name: 'ExpenseSettingEdit',
        props: true,
        meta: {
          menu: {
            name: '设置',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ExpenseSetting',
            label: '设置',
          },
          activeMenu: '/finance-mangement/expense-setting',
          permissions: '老人费用设置',
        },
        component: () => import('@/views/finance-management/expense-setting/edit/index.vue'),
      },
      {
        path: '/finance-mangement/expense-setting/create/:id',
        name: 'ExpenseSettingCreate',
        props: true,
        meta: {
          menu: {
            name: '添加',
            hidden: true,
          },
          breadcrumb: {
            parent: 'ExpenseSetting',
            label: '添加',
          },
          activeMenu: '/finance-mangement/expense-setting',
          permissions: '老人费用设置',
        },
        component: () => import('@/views/finance-management/expense-setting/create/index.vue'),
      },
      {
        path: '/finance-mangement/monthly-payment',
        name: 'MonthlyPayment',
        meta: {
          menu: {
            name: '月度缴费',
          },
          breadcrumb: {
            label: '月度缴费',
          },
          permissions: '月度缴费',
        },
        component: () => import('@/views/finance-management/monthly-payment/index.vue'),
      },
      {
        path: '/finance-mangement/monthly-payment/pay/:elderId',
        name: 'MonthlyPaymentPay',
        props: true,
        meta: {
          menu: {
            name: '缴费',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MonthlyPayment',
            label: '缴费',
          },
          activeMenu: '/finance-mangement/monthly-payment',
          permissions: '月度缴费',
        },
        component: () => import('@/views/finance-management/monthly-payment/pay/index.vue'),
      },
      {
        path: '/finance-mangement/monthly-payment/history/:elderId',
        name: 'MonthlyPaymentHistory',
        props: true,
        meta: {
          menu: {
            name: '缴费历史',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MonthlyPayment',
            label: '缴费历史',
          },
          activeMenu: '/finance-mangement/monthly-payment',
          permissions: '月度缴费',
        },
        component: () => import('@/views/finance-management/monthly-payment/history/index.vue'),
      },
      {
        path: '/finance-mangement/monthly-payment/history/:elderId/detail/:historyId',
        name: 'MonthlyPaymentHistoryDetail',
        props: true,
        meta: {
          menu: {
            name: '详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MonthlyPaymentHistory',
            label: '详情',
          },
          activeMenu: '/finance-mangement/monthly-payment',
          permissions: '月度缴费',
        },
        component: () => import('@/views/finance-management/monthly-payment/history/detail/index.vue'),
      },
      {
        path: '/finance-mangement/expenses',
        name: 'Expenses',
        props: true,
        meta: {
          menu: {
            name: '费用收支',
          },
          breadcrumb: {
            label: '费用收支',
          },
          permissions: '费用收支',
        },
        component: () => import('@/views/finance-management/expenses/index.vue'),
      },
    ],
  },
];

export default routes;
