import Layout from '@/views/layout/index.vue';
import serviceBasicDataManagementActiveIcon from '@/assets/menu/service-basic-data-management-active-icon.svg';
import serviceBasicDataManagementInactiveIcon from '@/assets/menu/service-basic-data-management-inactive-icon.svg';
import * as Ops from '@/plugins/v-permission/ops/index';

const routes = [
  {
    path: '/service-management',
    name: 'ServiceManagement',
    redirect: '/service-management/service-content',
    meta: {
      menu: {
        name: '服务基础数据管理',
        icon: {
          active: serviceBasicDataManagementActiveIcon,
          inactive: serviceBasicDataManagementInactiveIcon,
        },
      },
      permissions: Ops.or([
        '服务项目管理',
        '服务内容管理',
        '套餐管理',
        '临时工作类型',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/service-management/service-type',
        name: 'ServiceType',
        meta: {
          menu: {
            name: '服务项目管理',
          },
          breadcrumb: {
            label: '服务项目管理',
          },
          permissions: '服务项目管理',
        },
        component: () => import('@/views/service-management/service-type/index.vue'),
      },
      {
        path: '/service-management/service-project',
        name: 'ServiceProject',
        meta: {
          menu: {
            name: '服务内容管理',
          },
          breadcrumb: {
            label: '服务内容管理',
          },
          permissions: '服务内容管理',
        },
        component: () => import('@/views/service-management/service-project/index.vue'),
      },
      {
        path: '/service-management/package',
        name: 'Package',
        meta: {
          menu: {
            name: '套餐管理',
          },
          breadcrumb: {
            label: '套餐管理',
          },
          permissions: '套餐管理',
        },
        component: () => import('@/views/service-management/package/index.vue'),
      },
      {
        path: '/service-management/add-package',
        name: 'PackageAdd',
        meta: {
          menu: {
            name: '添加套餐',
            hidden: true,
          },
          breadcrumb: {
            label: '添加套餐',
            parent: 'Package',
          },
          activeMenu: '/service-management/package',
          permissions: '套餐管理',
        },
        component: () => import('@/views/service-management/package/add/index.vue'),
      },
      {
        path: '/service-management/edit-package/:id',
        name: 'PackageEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑套餐',
            hidden: true,
          },
          breadcrumb: {
            label: '编辑套餐',
            parent: 'Package',

          },
          activeMenu: '/service-management/package',
          permissions: '套餐管理',
        },
        component: () => import('@/views/service-management/package/edit/index.vue'),
      },
      {
        path: '/service-management/periodic-work-type',
        name: 'PeriodicWorkType',
        meta: {
          menu: {
            name: '临时工作类型',
          },
          breadcrumb: {
            label: '临时工作类型',
          },
          activeMenu: '/service-management/periodic-work-type',
          permissions: '临时工作类型',
        },
        component: () => import('@/views/service-management/periodic-work-type/index.vue'),
      },
    ],
  },
];

export default routes;
