import OSS from 'ali-oss';
// import { nanoid } from 'nanoid';
import { handleUploadFile } from '@/api/account';

export function createOSSClient(sts) {
  const ossOptins = {
    region: `oss-${sts.region}`,
    accessKeyId: sts.accessKeyId,
    accessKeySecret: sts.accessKeySecret,
    bucket: sts.bucketName,
    stsToken: sts.securityToken,
  };

  return new OSS(ossOptins);
}

// 上传单个文件
// export async function singleUpload(client, path, file) {
//   const objectBasePath = path;
//   const objectPath = `${objectBasePath}/${nanoid()}`;
//   const res = await client.put(objectPath, file.raw);
//   return {
//     oss: res,
//     file,
//   };
// }

// 上传单个文件
export async function singleUpload(basePath, fileName, fileType, attachment, file) {
  const formData = new FormData();
  formData.append('file', file.raw);
  const params = {
    query: {
      basePath,
      fileName,
      fileType,
    },
    data: formData,
  };
  if (attachment) {
    params.query.attachment = 'attachment';
  }
  const { data } = await handleUploadFile(params);
  return {
    oss: data.data,
    file,
  };
}

// 批量上传
// export function blukUpload(client, path, fileList) {
//   const objectBasePath = path;

//   return Promise.all(fileList.map(async (item) => {
//     const objectPath = `${objectBasePath}/${nanoid()}`;
//     const res = await client.put(objectPath, item.raw);
//     return {
//       oss: res,
//       file: item,
//     };
//   }));
// }
export function blukUpload(basePath, attachment, fileList) {
  return Promise.all(fileList.map(async (item) => {
    const formData = new FormData();
    formData.append('file', item.raw);
    const params = {
      query: {
        basePath,
        fileName: item.name,
        fileType: item.type,
      },
      data: formData,
    };
    if (attachment) {
      params.query.attachment = 'attachment';
    }
    const { data } = await handleUploadFile(params);
    return {
      oss: data.data,
      file: item,
    };
  }));
}

// 批量上传(回填用)
// export function blukUploadBackFill(client, path, fileList) {
//   const objectBasePath = path;

//   return Promise.all(fileList.map(async (item) => {
//     const objectPath = `${objectBasePath}/${nanoid()}`;
//     if (item.raw) {
//       const res = await client.put(objectPath, item.raw);
//       return {
//         oss: res,
//         file: item,
//       };
//     }
//     return {
//       oss: {
//         name: item.ossName,
//       },
//       file: {
//         name: item.fileName,
//         uploadType: item.uploadType,
//       },
//     };
//   }));
// }

// 批量上传(回填用)
export function blukUploadBackFill(basePath, attachment, fileList) {
  return Promise.all(fileList.map(async (item) => {
    if (item.raw) {
      const formData = new FormData();
      formData.append('file', item.raw);
      const params = {
        query: {
          basePath,
          fileName: item.name,
          fileType: item.type,
        },
        data: formData,
      };
      if (attachment) {
        params.query.attachment = 'attachment';
      }
      const { data } = await handleUploadFile(params);
      return {
        oss: data.data,
        file: item,
      };
    }
    return {
      oss: {
        ossName: item.ossName,
      },
      file: {
        name: item.fileName,
        uploadType: item.uploadType,
      },
    };
  }));
}

export function formatterUrl(baseUrl, fileUrl) {
  return `${baseUrl}${fileUrl}`;
}
