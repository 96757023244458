import { request } from '@/utils/request';

// 获取服务组织信息
export function getServiceOrgInfo() {
  return request({
    url: '/service-org/info',
    method: 'GET',
  });
}

// 编辑服务组织信息
export function editServiceOrgInfo(parameters) {
  return request({
    url: '/service-org/info/',
    method: 'PUT',
    data: parameters,
  });
}

// 获取服务组织项目列表
export function getServiceOrgItemList(parameters) {
  return request({
    url: '/service-org/item',
    method: 'GET',
    params: parameters,
  });
}

// 添加服务组织项目
export function addServiceOrgItem(parameters) {
  return request({
    url: '/service-org/item',
    method: 'POST',
    data: parameters,
  });
}

// 编辑服务组织项目
export function editServiceOrgItem(parameters) {
  return request({
    url: `/service-org/item/${parameters.id}`,
    method: 'PUT',
    data: parameters.data,
  });
}

// 删除服务组织项目
export function deleteServiceOrgItem(id) {
  return request({
    url: `/service-org/item/${id}`,
    method: 'DELETE',
  });
}

// 服务预约处理列表
export function getServiceOrgReservationList(parameters) {
  return request({
    url: '/service-org/reservation/list',
    method: 'GET',
    params: parameters,
  });
}

// 获取预约机构或驿站列表下拉
export function getServiceOrgReservationOrgList(parameters) {
  return request({
    url: '/service-org/reservation/org/list',
    method: 'GET',
    params: parameters,
  });
}

// 预约处理
export function disposeReservation(parameters) {
  return request({
    url: `/service-org/dispose/${parameters.id}`,
    method: 'PUT',
    data: parameters.data,
  });
}
