import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import hardActiveIcon from '@/assets/menu/hardware-active-icon.svg';
import hardInActiveIcon from '@/assets/menu/hardware-inactive-icon.svg';

const routes = [
  {
    path: '/hardware-data-observe',
    name: 'HardWareDataObserve',
    meta: {
      menu: {
        name: '硬件数据监测',
        icon: {
          active: hardActiveIcon,
          inactive: hardInActiveIcon,
        },
      },
      permissions: Ops.or([
        '数据检索', '统计数据', '实时数据',
      ]),
    },
    component: Layout,
    redirect: '/hardware-data-observe/current-data',
    children: [
      {
        path: '/hardware-data-observe/data-search',
        name: 'DataSearch',
        meta: {
          menu: {
            name: '数据检索',
          },
          breadcrumb: {
            label: '数据检索',
          },
          permissions: '数据检索',
        },
        component: () => import('@/views/hardware-data-observe/data-search/index.vue'),
      },
      {
        path: '/hardware-data-observe/data-statistical',
        name: 'DataStatistical',
        meta: {
          menu: {
            name: '统计数据',
          },
          breadcrumb: {
            label: '统计数据',
          },
          permissions: '统计数据',
        },
        component: () => import('@/views/hardware-data-observe/data-statistics/index.vue'),
      },
      {
        path: '/hardware-data-observe/current-data',
        name: 'HardWareCurrentData',
        meta: {
          menu: {
            name: '实时数据',
          },
          breadcrumb: {
            label: '实时数据',
          },
          permissions: '实时数据',
        },
        component: () => import('@/views/hardware-data-observe/current-data/index.vue'),
      },
    ],
  },
];

export default routes;
