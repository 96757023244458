import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import elderServiceActiveIcon from '@/assets/menu/elder-service-active-icon.svg';
import elderServiceInActiveIcon from '@/assets/menu/elder-service-inactive-icon.svg';

const routes = [
  {
    path: '/elderly-service',
    name: 'ElderlyService',
    meta: {
      menu: {
        name: '老年人服务',
        icon: {
          active: elderServiceActiveIcon,
          inactive: elderServiceInActiveIcon,
        },
      },
      permissions: Ops.or(['入院咨询', '办理入院', '入院管理']),
      orgType: 'institutions',
    },
    component: Layout,
    children: [
      {
        path: '/elderly-service/admission-consultation',
        name: 'AdmissionConsultation',
        meta: {
          menu: {
            name: '入院咨询',
          },
          breadcrumb: {
            label: '入院咨询',
          },
          permissions: '入院咨询',
        },
        component: () => import('@/views/elderly-service/admission-consultation/index.vue'),
      },
      {
        path: '/elderly-service/admission-handler',
        name: 'AdmissionHandler',
        meta: {
          menu: {
            name: '办理入院',
          },
          breadcrumb: {
            label: '办理入院',
          },
          permissions: '办理入院',
        },
        component: () => import('@/views/elderly-service/admission-handler/index.vue'),
      },
      {
        path: '/elderly-service/admission-handler/initiate-admission',
        name: 'InitiateAdmission',
        meta: {
          menu: {
            name: '发起入院',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AdmissionHandler',
            label: '发起入院',
          },
          activeMenu: '/elderly-service/admission-handler',
        },
        component: () => import(
          '@/views/elderly-service/admission-handler/components/user-overview/initiate-admission/index.vue'
        ),
      },
      {
        path: '/elderly-service/admission-handler/detail/:id',
        name: 'ElderlyDetail',
        props: true,
        meta: {
          menu: {
            name: '查看详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AdmissionHandler',
            label: '查看详情',
          },
          activeMenu: '/elderly-service/admission-handler',
        },
        component: () => import(
          '@/views/elderly-service/admission-handler/components/user-overview/detail/index.vue'
        ),
      },
      {
        path: '/elderly-service/admission-handler/edit/:id',
        name: 'ElderlyEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑用户',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AdmissionHandler',
            label: '编辑用户',
          },
          activeMenu: '/elderly-service/admission-handler',
        },
        component: () => import(
          '@/views/elderly-service/admission-handler/components/user-overview/edit-user-info/index.vue'
        ),
      },
      {
        path: '/elderly-service/admission-handler/upload-materials/:recordId',
        name: 'UploadMaterials',
        props: true,
        meta: {
          menu: {
            name: '上传材料',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AdmissionHandler',
            label: '上传材料',
          },
          activeMenu: '/elderly-service/admission-handler',
        },
        component: () => import(
          '@/views/elderly-service/admission-handler/components/upload-materials/upload/index.vue'
        ),
      },
      {
        path: '/elderly-service/admission-handler/materials-upload-detail/:id',
        name: 'MaterialsDetail',
        props: true,
        meta: {
          menu: {
            name: '查看材料',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AdmissionHandler',
            label: '查看材料',
          },
          activeMenu: '/elderly-service/admission-handler',
        },
        component: () => import(
          '@/views/elderly-service/admission-handler/components/upload-materials/detail/index.vue'
        ),
      },
      {
        path: '/elderly-service/admission-handler/materials-upload-modify/:id',
        name: 'MaterialsModify',
        props: true,
        meta: {
          menu: {
            name: '编辑材料',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AdmissionHandler',
            label: '编辑材料',
          },
          activeMenu: '/elderly-service/admission-handler',
        },
        component: () => import(
          '@/views/elderly-service/admission-handler/components/upload-materials/modify/index.vue'
        ),
      },
      {
        path: '/elderly-service/admission-handler/audit-materials-detail/:id',
        name: 'AuditMaterialsDetail',
        props: true,
        meta: {
          menu: {
            name: '查看材料',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AdmissionHandler',
            label: '查看材料',
          },
          activeMenu: '/elderly-service/admission-handler',
        },
        component: () => import(
          '@/views/elderly-service/admission-handler/components/audit-materials/detail/index.vue'
        ),
      },
      {
        path: '/elderly-service/admission-handler/audit-materials-examine/:id',
        name: 'AuditMaterialsExamine',
        props: true,
        meta: {
          menu: {
            name: '审核材料',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AdmissionHandler',
            label: '审核材料',
          },
          activeMenu: '/elderly-service/admission-handler',
        },
        component: () => import(
          '@/views/elderly-service/admission-handler/components/audit-materials/examine/index.vue'
        ),
      },
      {
        path: '/elderly-service/admission-handler/sign-contract-detail/:id',
        name: 'SignContractDetail',
        props: true,
        meta: {
          menu: {
            name: '签订合同',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AdmissionHandler',
            label: '签订合同',
          },
          activeMenu: '/elderly-service/admission-handler',
        },
        component: () => import(
          '@/views/elderly-service/admission-handler/components/sign-contract/sign/index.vue'
        ),
      },
      {
        path: '/elderly-service/admission-handler/view-elderly-info/:id',
        name: 'ViewElderlyInfo',
        props: true,
        meta: {
          menu: {
            name: '查看详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'AdmissionHandler',
            label: '查看详情',
          },
          activeMenu: '/elderly-service/admission-handler',
        },
        component: () => import(
          '@/views/elderly-service/admission-handler/components/confirm-check-in/detail/index.vue'
        ),
      },
      {
        path: '/elderly-service/admission-management',
        name: 'AdmissionManagement',
        meta: {
          menu: {
            name: '入院管理',
          },
          breadcrumb: {
            label: '入院管理',
          },
          permissions: '入院管理',
        },
        component: () => import('@/views/elderly-service/admission-management/index.vue'),
      },
    ],
  },
];

export default routes;
