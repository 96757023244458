import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import activeIcon from '@/assets/menu/hardware-manage-active-icon.svg';
import inActiveIcon from '@/assets/menu/hardware-manage-inactive-icon.svg';

const routes = [
  {
    path: '/hardware-manage',
    name: 'HardWareManage',
    meta: {
      menu: {
        name: '硬件管理',
        icon: {
          active: activeIcon,
          inactive: inActiveIcon,
        },
      },
      permissions: Ops.or([
        '设备类型管理', '设备信息管理', '产品信息管理', '老人使用管理', '床位使用管理',
      ]),
    },
    component: Layout,
    redirect: '/hardware-manage/equipment-type-manage',
    children: [
      {
        path: '/hardware-manage/equipment-type-manage',
        name: 'equipmentTypeManage',
        meta: {
          menu: {
            name: '设备类型管理',
          },
          breadcrumb: {
            label: '设备类型管理',
          },
          permissions: '设备类型管理',
        },
        component: () => import('@/views/hardware-manage/equipment-type-manage/index.vue'),
      },
      {
        path: '/hardware-manage/equipment-info-manage',
        name: 'EquipmentInfoManage',
        meta: {
          menu: {
            name: '设备信息管理',
          },
          breadcrumb: {
            label: '设备信息管理',
          },
          permissions: '设备信息管理',
        },
        component: () => import('@/views/hardware-manage/equipment-info-manage/index.vue'),
      },
      {
        path: '/hardware-manage/product-info-manage',
        name: 'ProductInfoManage',
        meta: {
          menu: {
            name: '产品信息管理',
          },
          breadcrumb: {
            label: '产品信息管理',
          },
          permissions: '产品信息管理',
        },
        component: () => import('@/views/hardware-manage/product-info-manage/index.vue'),
      },

      {
        path: '/hardware-manage/elder-use-manage',
        name: 'ElderUseManage',
        meta: {
          menu: {
            name: '老人使用管理',
          },
          breadcrumb: {
            label: '老人使用管理',
          },
          permissions: '老人使用管理',
        },
        component: () => import('@/views/hardware-manage/elder-use-manage/index.vue'),
      },

      {
        path: '/hardware-manage/bed-use-manage',
        name: 'BedUseManage',
        meta: {
          menu: {
            name: '床位使用管理',
          },
          breadcrumb: {
            label: '床位使用管理',
          },
          permissions: '床位使用管理',
        },
        component: () => import('@/views/hardware-manage/bed-use-manage/index.vue'),
      },

    ],
  },
];

export default routes;
