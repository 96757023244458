import dayjs from 'dayjs';
import {
  login, getUserInfo, LoginBySafe, loginBy2fa,
} from '@/api/account';
import { removeToken } from '@/utils/local-store-token';
import { getMessageNumber } from '@/api/message';
import { sm2 } from 'sm-crypto';

// 登录;
export async function LOGIN({ commit }, userInfo) {
  const account = userInfo.account.trim();
  const { password } = userInfo;
  const loginResponse = await login({ account, password });
  const { token } = loginResponse.data.data;

  commit('SET_TOKEN', token);
  return loginResponse;
}

export async function LOGIN_SAFE({ commit }, userInfo) {
  const account = userInfo.account.trim();
  const { password, code, publicKey } = userInfo;
  const loginJSON = JSON.stringify({ account, password, code });

  const encryptData = sm2.doEncrypt(loginJSON, publicKey, 1);

  const loginResponse = await LoginBySafe({ data: `04${encryptData}` });

  const { token } = loginResponse.data.data;

  commit('SET_TOKEN', token);
  return loginResponse;
}

export async function LOGIN_2_FA({ commit }, userInfo) {
  const account = userInfo.account.trim();
  const { password, code } = userInfo;
  const loginResponse = await loginBy2fa({ account, password, code });
  const { token } = loginResponse.data.data;

  commit('SET_TOKEN', token);
  return loginResponse;
}

// 获取用户信息;
export async function GET_INFO({ commit }) {
  const getUserInfoResponse = await getUserInfo();
  const { data } = getUserInfoResponse;
  commit('SET_ROLES', data.data.roleList);
  commit('SET_ACCOUNT', data.data.account);
  commit('SET_NAME', data.data.name);
  commit('SET_ORG_NAME', data.data.organizationName);
  commit('SET_ORG_TYPE', data.data.organizationType);
  commit('SET_POSITIONLIST', data.data.positionList);
  commit('SET_MENU_LIST', data.data.menuList);

  if (data.data.nextChangePwdTime) {
    const needChangePwd = dayjs().isAfter(data.data.nextChangePwdTime);
    commit('SET_NEED_CHANGE_PWD', needChangePwd);
  } else {
    commit('SET_NEED_CHANGE_PWD', false);
  }

  if (Array.isArray(data.data.menuList)) {
    const permissions = new Set(
      data.data.menuList.map((menuItem) => menuItem.name),
    );
    commit('SET_PERMISSIONS', permissions);
  }
}

// 获取消息数量
export async function GET_MESSAGE_NUMBER({ commit }) {
  const messageInfo = await getMessageNumber();
  commit('SET_UNREADMESSAGE', messageInfo.data.data.unread);
}

// 登出;
export async function LOGOUT({ commit }) {
  // await logout();
  commit('SET_TOKEN', '');
  commit('SET_ROLES', []);
  removeToken();
  window.location.reload();
}

// 前端登出
export async function FED_LOGOUT({ commit }) {
  commit('SET_TOKEN', '');
  removeToken();
  // window.location.reload();
}
