import Layout from '@/views/layout/index.vue';
import taskListActiveIcon from '@/assets/menu/task-list-active-icon.svg';
import taskListInActiveIcon from '@/assets/menu/task-list-inactive-icon.svg';

const routes = [
  {
    path: '/training-manage',
    name: 'TrainingManage',
    meta: {
      menu: {
        name: '培训管理',
        icon: {
          active: taskListActiveIcon,
          inactive: taskListInActiveIcon,
        },
      },
    },
    component: Layout,
    children: [
      {
        path: '/training-manage/staff-training-info',
        name: 'StaffTrainingInfo',
        meta: {
          menu: {
            name: '员工培训信息',
          },
          breadcrumb: {
            label: '员工培训信息',
          },
        },
        component: () => import('@/views/training-manage/staff-training-info.vue'),
      },
      {
        path: '/training-manage/course-apply-manage',
        name: 'CourseApplyManage',
        meta: {
          menu: {
            name: '课程报名管理',
          },
          breadcrumb: {
            label: '课程报名管理',
          },
        },
        component: () => import('@/views/training-manage/course-apply-manage.vue'),
      },
      {
        path: '/training-manage/online-course-manage',
        name: 'OnlineCourseManage',
        meta: {
          menu: {
            name: '线上课程管理',
          },
          breadcrumb: {
            label: '线上课程管理',
          },
        },
        component: () => import('@/views/training-manage/online-course-manage.vue'),
      },
      {
        path: '/training-manage/knowledge-library',
        name: 'KnowledgeLibrary',
        meta: {
          menu: {
            name: '知识库管理',
          },
          breadcrumb: {
            label: '知识库管理',
          },
        },
        component: () => import('@/views/training-manage/knowledge-library.vue'),
      },
      {
        path: '/training-manage/training-class-apply',
        name: 'TrainingClassApply',
        meta: {
          menu: {
            name: '培训班报名',
          },
          breadcrumb: {
            label: '培训班报名',
          },
        },
        component: () => import('@/views/training-manage/training-class-apply.vue'),
      },
      {
        path: '/training-manage/staff-grade-info',
        name: 'StaffGradeInfo',
        meta: {
          menu: {
            name: '员工成绩信息',
          },
          breadcrumb: {
            label: '员工成绩信息',
          },
        },
        component: () => import('@/views/training-manage/staff-grade-info.vue'),
      },
    ],
  },
];

export default routes;
