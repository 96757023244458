import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import pharmacyManagementActiveIcon from '@/assets/menu/pharmacy-management-active-icon.svg';
import pharmacyManagementInactiveIcon from '@/assets/menu/pharmacy-management-inactive-icon.svg';

const routes = [
  {
    path: '/pharmacy-management',
    name: 'PharmacyManagement',
    redirect: '/pharmacy-management/drug-name-management',
    meta: {
      menu: {
        name: '药房管理',
        icon: {
          active: pharmacyManagementActiveIcon,
          inactive: pharmacyManagementInactiveIcon,
        },
      },
      permissions: Ops.or([
        '药品名录管理',
        '药房库存',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/pharmacy-management/drug-name-management',
        name: 'DrugNameManagement',
        meta: {
          menu: {
            name: '药品名录管理',
          },
          breadcrumb: {
            label: '药品名录管理',
          },
          permissions: '药品名录管理',
        },
        component: () => import('@/views/pharmacy-management/drug-name-management/index.vue'),
      },
      {
        path: '/pharmacy-management/drug-name-management/add',
        name: 'DrugNameManagementAdd',
        meta: {
          menu: {
            name: '添加',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DrugNameManagement',
            label: '添加',
          },
          activeMenu: '/pharmacy-management/drug-name-management',
          permissions: '药品名录管理',
        },
        component: () => import('@/views/pharmacy-management/drug-name-management/add/index.vue'),
      },
      {
        path: '/pharmacy-management/drug-name-management/edit/:id',
        name: 'DrugNameManagementEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑',
            hidden: true,
          },
          breadcrumb: {
            parent: 'DrugNameManagement',
            label: '编辑',
          },
          activeMenu: '/pharmacy-management/drug-name-management',
          permissions: '药品名录管理',
        },
        component: () => import('@/views/pharmacy-management/drug-name-management/edit/index.vue'),
      },
      {
        path: '/pharmacy-management/pharmacy-inventory',
        name: 'PharmacyInventory',
        meta: {
          menu: {
            name: '药房库存',
          },
          breadcrumb: {
            label: '药房库存',
          },
          permissions: '药房库存',
        },
        component: () => import('@/views/pharmacy-management/pharmacy-inventory/index.vue'),
      },
      {
        path: '/pharmacy-management/pharmacy-inventory/add',
        name: 'PharmacyInventoryAdd',
        meta: {
          menu: {
            name: '添加',
            hidden: true,
          },
          breadcrumb: {
            parent: 'PharmacyInventory',
            label: '添加',
          },
          activeMenu: '/pharmacy-management/pharmacy-inventory',
          permissions: '药房库存',
        },
        component: () => import('@/views/pharmacy-management/pharmacy-inventory/add/index.vue'),
      },
      {
        path: '/pharmacy-management/pharmacy-inventory/warehousing',
        name: 'PharmacyInventoryWarehousing',
        meta: {
          menu: {
            name: '入库记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'PharmacyInventory',
            label: '入库记录',
          },
          activeMenu: '/pharmacy-management/pharmacy-inventory',
          permissions: '药房库存',
        },
        component: () => import('@/views/pharmacy-management/pharmacy-inventory/warehousing/index.vue'),
      },
      {
        path: '/pharmacy-management/pharmacy-inventory/ex-warehouse',
        name: 'PharmacyInventoryExWarehouse',
        meta: {
          menu: {
            name: '出库记录',
            hidden: true,
          },
          breadcrumb: {
            parent: 'PharmacyInventory',
            label: '出库记录',
          },
          activeMenu: '/pharmacy-management/pharmacy-inventory',
          permissions: '药房库存',
        },
        component: () => import('@/views/pharmacy-management/pharmacy-inventory/ex-warehouse/index.vue'),
      },
    ],
  },
];

export default routes;
