import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import taskListActiveIcon from '@/assets/menu/task-list-active-icon.svg';
import taskListInActiveIcon from '@/assets/menu/task-list-inactive-icon.svg';

const routes = [
  {
    path: '/solution-manage',
    name: 'SolutionManage',
    meta: {
      menu: {
        name: '照护方案管理',
        icon: {
          active: taskListActiveIcon,
          inactive: taskListInActiveIcon,
        },
      },
      permissions: Ops.or([
        '老年人服务管理',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/solution-manage/solution-list',
        name: 'SolutionList',
        meta: {
          menu: {
            name: '照护方案制定',
          },
          breadcrumb: {
            label: '照护方案制定',
          },
        },
        component: () => import('@/views/solution-management/solution-management.vue'),
      },
    ],
  },
];

export default routes;
