import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import organizationalStructureActiveIcon from '@/assets/menu/organizational-structure-active-icon.svg';
import organizationalStructureInactiveIcon from '@/assets/menu/organizational-structure-inactive-icon.svg';

const routes = [
  {
    path: '/organizational-structure',
    name: 'OrganizationalStructure',
    redirect: '/organizational-structure/structure-management',
    meta: {
      menu: {
        name: '组织架构',
        icon: {
          active: organizationalStructureActiveIcon,
          inactive: organizationalStructureInactiveIcon,
        },
      },
      permissions: Ops.or([
        '架构管理',
        '员工管理',
        '角色管理',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/organizational-structure/structure-management',
        name: 'StructureManagement',
        meta: {
          menu: {
            name: '架构管理',
          },
          breadcrumb: {
            label: '架构管理',
          },
          permissions: '架构管理',
        },
        component: () => import('@/views/organizational-structure/structure-management/index.vue'),
      },
      {
        path: '/organizational-structure/staff-management',
        name: 'StaffManagement',
        meta: {
          menu: {
            name: '员工管理',
          },
          breadcrumb: {
            label: '员工管理',
          },
          permissions: '员工管理',
        },
        component: () => import('@/views/organizational-structure/staff-management/index.vue'),
      },
      {
        path: '/organizational-structure/staff-management/add/:departmentId',
        name: 'StaffManagementAdd',
        props: true,
        meta: {
          menu: {
            name: '添加员工',
            hidden: true,
          },
          breadcrumb: {
            parent: 'StaffManagement',
            label: '添加员工',
          },
          activeMenu: '/organizational-structure/staff-management',
          permissions: '员工管理',
        },
        component: () => import('@/views/organizational-structure/staff-management/add/index.vue'),
      },
      {
        path: '/organizational-structure/staff-management/edit/:staffId',
        name: 'StaffManagementEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑员工',
            hidden: true,
          },
          breadcrumb: {
            parent: 'StaffManagement',
            label: '编辑员工',
          },
          activeMenu: '/organizational-structure/staff-management',
          permissions: '员工管理',
        },
        component: () => import('@/views/organizational-structure/staff-management/edit/index.vue'),
      },
      {
        path: '/organizational-structure/role-management',
        name: 'RoleManagement',
        meta: {
          menu: {
            name: '角色管理',
          },
          breadcrumb: {
            label: '角色管理',
          },
          permissions: '角色管理',
        },
        component: () => import('@/views/organizational-structure/role-management/index.vue'),
      },
      {
        path: '/organizational-structure/role-management/add',
        name: 'RoleManagementAdd',
        meta: {
          menu: {
            name: '添加角色',
            hidden: true,
          },
          breadcrumb: {
            parent: 'RoleManagement',
            label: '添加角色',
          },
          activeMenu: '/organizational-structure/role-management',
          permissions: '角色管理',
        },
        component: () => import('@/views/organizational-structure/role-management/add-role/index.vue'),
      },
      {
        path: '/organizational-structure/role-management/check/:id',
        name: 'RoleManagementCheck',
        props: true,
        meta: {
          menu: {
            name: '查看角色',
            hidden: true,
          },
          breadcrumb: {
            parent: 'RoleManagement',
            label: '查看角色',
          },
          activeMenu: '/organizational-structure/role-management',
          permissions: '角色管理',
        },
        component: () => import('@/views/organizational-structure/role-management/check-role/index.vue'),
      },
      {
        path: '/organizational-structure/role-management/edit/:id',
        name: 'RoleManagementEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑角色',
            hidden: true,
          },
          breadcrumb: {
            parent: 'RoleManagement',
            label: '编辑角色',
          },
          activeMenu: '/organizational-structure/role-management',
          permissions: '角色管理',
        },
        component: () => import('@/views/organizational-structure/role-management/edit-role/index.vue'),
      },
    ],
  },
];

export default routes;
