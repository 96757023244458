import Layout from '@/views/layout/index.vue';
import bedOverviewActiveIcon from '@/assets/menu/bed-overview-active-icon.svg';
import bedOverviewInactiveIcon from '@/assets/menu/bed-overview-inactive-icon.svg';

const routes = [
  {
    path: '/bed-overview',
    name: 'BedOverview',
    redirect: '/bed-overview/list',
    meta: {
      menu: {
        name: '床位总览',
        icon: {
          active: bedOverviewActiveIcon,
          inactive: bedOverviewInactiveIcon,
        },
      },
      permissions: '床位总览',
    },
    component: Layout,
    children: [
      {
        path: '/bed-overview/list',
        name: 'BedOverviewList',
        meta: {
          menu: {
            name: '床位总览',
          },
          breadcrumb: {
            label: '床位总览',
          },
        },
        component: () => import('@/views/bed-overview/index.vue'),
      },
      {
        path: '/bed-overview/detail/:elderId',
        name: 'BedOverviewDetail',
        props: true,
        meta: {
          menu: {
            name: '老人详情',
          },
          breadcrumb: {
            label: '老人详情',
            parent: 'BedOverview',
          },
          hidden: true,
          activeMenu: '/bed-overview/list',
        },
        component: () => import('@/views/bed-overview/detail/index.vue'),
      },
    ],
  },
];

export default routes;
