<template>
  <div class="service-reservation-appointment app-container">
    <el-card
      shadow="never"
      class="second-card-margin"
    >
      <el-form
        class="is-flex-between-wrap"
        size="small"
        label-width="69px"
      >
        <el-form-item label="服务项目">
          <el-input
            class="form-input-item"
            v-model="filterForm.name"
          >
          </el-input>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            @click="onSearcchClick"
          >查询</el-button>
          <el-button @click="onResetClick">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="never">
      <ZTable
        v-loading="loading"
        ref="table"
        :data="data"
        :current-page.sync="pagination.page"
        :page-size.sync="pagination.pageSize"
        :total="pagination.total"
        @query-change="loadData"
      >
        <el-table-column
          label="服务项目"
          prop="name"
        ></el-table-column>
        <el-table-column
          label="项目费用"
          :formatter="formatterFee"
        ></el-table-column>
        <el-table-column
          label="服务内容"
          prop="content"
        ></el-table-column>
        <el-table-column label="操作">
          <template v-slot="{row}">
            <el-button
              type="text"
              @click="onLaunchClick(row)"
            >立即预约</el-button>
          </template>
        </el-table-column>
      </ZTable>
    </el-card>
  </div>
</template>

<script>
import ZTable from '@/components/z-table/index.vue';
import { getOrgReservationServiceItemList } from '@/api/org-reservation-service​';
import errorHandler from '@/plugins/error-handler';
import { getServerPageQuery } from '@/utils';
import LaunchDialog from './launch.vue';

export default {
  name: 'ServiceProjectManagement',
  props: {
    organizeId: {
      required: true,
    },
  },
  components: {
    ZTable,
  },
  data() {
    return {
      filterForm: {
        name: '',
      },

      loading: false,

      pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
      },

      data: [],
    };
  },
  methods: {
    async loadData(event) {
      try {
        this.loading = true;
        const { page, pageSize } = event;
        const params = {
          id: this.organizeId,
          query: {
            ...getServerPageQuery(page, pageSize),
          },
        };
        if (this.filterForm.name) {
          params.query.name = this.filterForm.name;
        }
        const { data } = await getOrgReservationServiceItemList(params);
        this.data = data.data.records;
        this.pagination.total = data.data.count;
      } catch (error) {
        console.error(error);
        errorHandler('获取列表失败', error);
      } finally {
        this.loading = false;
      }
    },

    formatterFee(row) {
      return `${row.fee}元/次`;
    },

    onLaunchClick(row) {
      this.$z.$dialog({
        parent: this,
        component: LaunchDialog,
        serviceOrgItemId: row.id,
      });
    },

    onSearcchClick() {
      this.$refs.table.setPagination({ page: 1 }, true);
    },

    onResetClick() {
      this.filterForm = {
        name: '',
      };
      this.$refs.table.setPagination({ page: 1, pageSize: 20 }, true);
    },
  },
};
</script>

<style>
</style>
