<template>
  <div class="service-reservation-processing-handler app-container">
    <el-card shadow="never">
      <el-form
        label-suffix=":"
        size="small"
      >
        <div class="title">预约信息</div>
        <el-form-item label="服务组织">{{ data.serviceOrgName }}</el-form-item>
        <el-form-item label="服务项目">{{ data.serviceOrgItemName }}</el-form-item>
        <el-form-item label="服务内容">{{ data.reservationContent }}</el-form-item>
        <el-form-item label="预约机构/驿站">{{ data.reservationOrgName }}</el-form-item>
        <el-form-item label="老人姓名">{{ data.elderName }}</el-form-item>
        <el-form-item label="性别">{{ data.gender }}</el-form-item>
        <el-form-item label="联系方式">{{ data.phone }}</el-form-item>
        <el-form-item label="老人住址">{{ data.address }}</el-form-item>
        <el-form-item label="预期服务时间">{{ formatterTime(data.reservationServiceTime) }}</el-form-item>
        <div class="title">分配信息</div>
        <el-form-item label="分配人员">{{ data.staffName }}</el-form-item>
        <el-form-item label="服务时间">{{ formatterTime(data.serviceTime) }}</el-form-item>
        <div class="title">评价信息</div>
        <el-form-item label="服务评价">{{ data.serviceEvaluate }}</el-form-item>
        <el-form-item label="备注">{{ data.evaluateRemark || '暂无' }}</el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getOrgReservationServiceRecordDetail } from '@/api/org-reservation-service​';
import errorHandler from '@/plugins/error-handler';
import { formatterDateTime } from '@/utils/formatter-time';

export default {
  name: 'ServiceReservationProcessingHandler',
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      form: {
        staffName: '',
        staffId: '',
        serviceTime: '',
      },

      loading: false,

      data: {
        elderName: '',
        gender: '',
        phone: '',
        address: '',
        serviceOrgItemName: '',
        reservationServiceTime: '',
        reservationContent: '',
        reservationOrgName: '',
        status: '',
        staffName: '',
        serviceTime: '',
        serviceEvaluate: '',
        evaluateRemark: '',
      },
    };
  },
  created() {
    this.getOrgReservationServiceRecordDetail();
  },
  methods: {
    async getOrgReservationServiceRecordDetail() {
      try {
        const { data } = await getOrgReservationServiceRecordDetail(this.id);
        this.data = {
          ...this.data,
          ...data.data,
        };
      } catch (error) {
        errorHandler('获取记录详情失败', error);
      }
    },

    formatterTime(time) {
      if (time) {
        return formatterDateTime(time);
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.service-reservation-processing-handler {
  .title {
    font-weight: bold;
  }
}
</style>
