import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import InstitutionalReservationServiceActiveIcon from '@/assets/menu/institutional-reservation-service-active-icon.svg';
import InstitutionalReservationServiceInactiveIcon from '@/assets/menu/institutional-reservation-service-inactive-icon.svg';
import ServiceReservation from '@/views/institutional-reservation-service/service-reservation/index.vue';
import ServiceReservationDetail from '@/views/institutional-reservation-service/service-reservation/check/index.vue';
import ServiceReservationAppointment from '@/views/institutional-reservation-service/service-reservation/appointment/index.vue';
import AppointmentRecord from '@/views/institutional-reservation-service/appointment-record/index.vue';
import AppointmentRecordDetail from '@/views/institutional-reservation-service/appointment-record/detail/index.vue';
import ServiceEvaluation from '@/views/institutional-reservation-service/service-evaluation/list.vue';
import ServiceEvaluationDetail from '@/views/institutional-reservation-service/service-evaluation/detail/detail.vue';

const routes = [
  {
    name: 'InstitutionalReservationService',
    path: '/institutional-reservation-service',
    redirect: '/institutional-reservation-service/service-reservation',
    meta: {
      menu: {
        name: '机构预约服务',
        icon: {
          active: InstitutionalReservationServiceActiveIcon,
          inactive: InstitutionalReservationServiceInactiveIcon,
        },
      },
      permissions: Ops.or([
        '服务预约',
        '预约记录',
        '服务评价',
      ]),
    },
    component: Layout,
    children: [
      {
        name: 'ServiceReservation',
        path: '/institutional-reservation-service/service-reservation',
        meta: {
          menu: {
            name: '服务预约',
          },
          breadcrumb: {
            label: '服务预约',
          },
          permissions: '服务预约',
        },
        component: ServiceReservation,
      },
      {
        name: 'ServiceReservationDetail',
        path: '/institutional-reservation-service/service-reservation/detail/:organizeId',
        props: true,
        meta: {
          menu: {
            name: '查看详情',
            hidden: true,
          },
          breadcrumb: {
            label: '查看详情',
            parent: 'ServiceReservation',
          },
          activeMenu: '/institutional-reservation-service/service-reservation',
          permissions: '服务预约',
        },
        component: ServiceReservationDetail,
      },
      {
        name: 'ServiceReservationAppointment',
        path: '/institutional-reservation-service/service-reservation/appointment/:organizeId',
        props: true,
        meta: {
          menu: {
            name: '服务列表',
            hidden: true,
          },
          breadcrumb: {
            label: '服务列表',
            parent: 'ServiceReservation',
          },
          permissions: '服务预约',
          activeMenu: '/institutional-reservation-service/service-reservation',
        },
        component: ServiceReservationAppointment,
      },
      {
        name: 'AppointmentRecord',
        path: '/institutional-reservation-service/appointment-record',
        meta: {
          menu: {
            name: '预约记录',
          },
          breadcrumb: {
            label: '预约记录',
          },
        },
        permissions: '预约记录',
        component: AppointmentRecord,
      },
      {
        name: 'AppointmentRecordDetail',
        path: '/institutional-reservation-service/appointment-record/detail/:recordId',
        props: true,
        meta: {
          menu: {
            name: '查看进度',
            hidden: true,
          },
          breadcrumb: {
            label: '查看进度',
            parent: 'AppointmentRecord',
          },
          permissions: '预约记录',
          activeMenu: '/institutional-reservation-service/appointment-record',
        },
        component: AppointmentRecordDetail,
      },
      {
        name: 'ServiceEvaluation',
        path: '/institutional-reservation-service/service-evaluation',
        meta: {
          menu: {
            name: '服务评价',
          },
          breadcrumb: {
            label: '服务评价',
          },
          permissions: '服务评价',
        },
        component: ServiceEvaluation,
      },
      {
        name: 'EvaluationDetail',
        path: '/institutional-reservation-service/service-evaluation/detail/:recordId',
        props: true,
        meta: {
          menu: {
            name: '查看详情',
            hidden: true,
          },
          breadcrumb: {
            label: '查看详情',
            parent: 'ServiceEvaluation',
          },
          activeMenu: '/institutional-reservation-service/service-evaluation',
        },
        component: ServiceEvaluationDetail,
      },
    ],
  },
];

export default routes;
