<template>
  <div class="service-organization-registration-edit app-container">
    <el-card shadow="never">
      <el-form
        label-suffix=":"
        label-width="110px"
        label-position="left"
        size="small"
      >
        <div class="is-flex-between-wrap">
          <el-form-item
            class="flex-1"
            label="服务组织名称"
            required
            :error="$et($v.data.name)"
          >
            <el-input
              class="form-input-item"
              v-model="data.name"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="flex-1"
            label="服务组织地址"
            required
            :error="$et($v.data.address)"
          >
            <el-input
              class="form-input-item"
              v-model="data.address"
            ></el-input>
          </el-form-item>
        </div>
        <div class="is-flex-between-wrap">
          <el-form-item
            class="flex-1"
            label="负责人"
            required
            :error="$et($v.data.director)"
          >
            <el-input
              class="form-input-item"
              v-model="data.director"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="flex-1"
            label="联系电话"
            required
            :error="$et($v.data.contactDetail)"
          >
            <el-input
              class="form-input-item"
              v-model="data.contactDetail"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item
          label="服务组织简介"
          required
          :error="$et($v.data.introduction)"
        >
          <el-input
            type="textarea"
            rows="5"
            v-model="data.introduction"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="服务组织图片"
          required
          :error="$et($v.data.serviceOrgPhotos)"
        >
          <div>
            <el-upload
              action
              multiple
              :auto-upload="false"
              list-type="picture-card"
              :file-list="data.serviceOrgPhotos"
              :on-remove="handleRemove"
              :on-change="handleChange"
              accept="image/*"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <!-- <img
              class="organization-image"
              v-for="(item, index) in data.image"
              :key="index"
              :src="item"
            > -->
          </div>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          size="small"
          @click="onCancelClick"
        >取消</el-button>
        <el-button
          :loading="loading"
          type="primary"
          size="small"
          @click="onSaveClick"
        >保存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
  getServiceOrgInfo,
  editServiceOrgInfo,
} from '@/api/service-org';
import errorHandler from '@/plugins/error-handler';
import {
  blukUpload,
  formatterUrl,
} from '@/utils/upload';
import fileBaseUrl from '@/config/index';

export default {
  name: 'ServiceOrganizationRegistrationEdit',
  data() {
    return {
      fileBaseUrl,

      data: {
        name: '',
        address: '',
        contactDetail: '',
        director: '',
        introduction: '',
        serviceOrgPhotos: [],
      },
      loading: false,
    };
  },
  validations: {
    data: {
      name: {
        required,
      },
      address: {
        required,
      },
      contactDetail: {
        required,
      },
      director: {
        required,
      },
      introduction: {
        required,
      },
      serviceOrgPhotos: {
        required,
      },
    },
  },
  errors: {
    required: '该项不能为空',
  },
  created() {
    this.getServiceOrgInfo();
  },
  methods: {
    async getServiceOrgInfo() {
      try {
        const { data } = await getServiceOrgInfo();
        this.data = {
          ...this.data,
          ...data.data,
          serviceOrgPhotos: data.data.serviceOrgPhotos.map((item) => ({
            ...item,
            url: formatterUrl(this.fileBaseUrl.bucketUrl.inst, item.ossName),
          })),
        };
      } catch (error) {
        console.error(error);
        errorHandler('获取服务组织详情失败', error);
      }
    },

    handleChange(file, fileList) {
      this.data.serviceOrgPhotos = fileList;
    },

    handleRemove(file, fileList) {
      this.data.serviceOrgPhotos = fileList;
    },

    async onSaveClick() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.loading = true;
        const current = this.data.serviceOrgPhotos.reduce((acc, cur) => {
          if (cur.raw) {
            acc.notUploaded.push({
              ...cur,
              type: 'image',
            });
          } else {
            acc.uploaded.push({
              fileName: cur.fileName,
              ossName: cur.ossName,
            });
          }
          return acc;
        }, {
          uploaded: [],
          notUploaded: [],
        });

        const uploadList = await blukUpload(this.fileBaseUrl.bucketName.inst,
          false,
          current.notUploaded);
        const params = {
          name: this.data.name,
          contactDetail: this.data.contactDetail,
          address: this.data.address,
          director: this.data.director,
          introduction: this.data.introduction,
          serviceOrgPhotos: uploadList.map((item) => ({
            ossName: item.oss.ossName,
            fileName: item.file.name,
          })).concat(current.uploaded),
        };
        await editServiceOrgInfo(params);
        this.$message.success('编辑服务组织信息成功');
        this.$router.back();
      } catch (error) {
        console.error(error);
        errorHandler('编辑服务组织信息失败', error);
      } finally {
        this.loading = false;
      }
    },

    onCancelClick() {
      this.$router.back();
    },

  },
};
</script>

<style lang="scss" scoped>
.service-organization-registration-edit {
  .flex-1 {
    flex: 1;
  }
}
.organization-image {
  width: 337px;
  height: 161px;
  margin-right: 20px;
}
</style>
