<template>
  <el-dialog
    ref="dialog"
    :title="title"
    :visible.sync="dialogVisible"
    width="800px"
    @closed="onDialogHide"
  >
    <div class="is-justify-content-between">
      <div class="tree-box">
        <h3 class="title">部门架构</h3>
        <el-tree
          :data="data"
          node-key="id"
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <div class="table-box">
        <h3 class="title">员工列表</h3>
        <div class="department">{{info.name||'请选择部门'}}</div>
        <ZTable
          v-loading="loading"
          ref="table"
          :data="staffData"
          :current-page.sync="pagination.page"
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          @query-change="dataList"
        >
          <el-table-column
            prop="name"
            label="员工姓名"
          >
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="{row}">
              <el-button
                @click="onChoiceClick(row)"
                type="text"
              >选择</el-button>
            </template>
          </el-table-column>
        </ZTable>
      </div>
    </div>
    <span slot="footer">
      <el-button
        @click="onCancelClick"
        size="small"
      >取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getDepartmentTree } from '@/api/department';
import { getDepartmentStaff } from '@/api/staff';
import handlerError from '@/plugins/error-handler';
import ZTable from '@/components/z-table/index.vue';

export default {
  props: {
    title: {
      default: '人员选择',
    },
  },
  components: {
    ZTable,
  },
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
      },
      loading: false,
      dialogVisible: false,
      data: [],
      staffData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      info: {},
    };
  },
  async created() {
    try {
      const { data } = await getDepartmentTree();
      this.data = data.data;
    } catch (error) {
      handlerError('获取部门列表失败', error);
    }
  },
  methods: {
    async handleNodeClick(info) {
      this.info = info;
      this.$refs.table.setPagination({ page: 1 }, true);
    },
    async dataList(event) {
      if (this.info.id) {
        const parameters = {
          id: this.info.id,
          params: {
            skip: (event.page - 1) * event.pageSize,
            limit: event.pageSize,
          },
        };
        try {
          this.loading = true;
          const { data } = await getDepartmentStaff(parameters);
          this.staffData = data.data.records;
          this.pagination.total = data.data.count;
        } catch (error) {
          handlerError('获取员工列表失败');
        } finally {
          this.loading = false;
        }
      }
    },
    onChoiceClick(row) {
      this.$emit('ok', row);
      this.hide();
    },
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
    onDialogHide() {
      this.$emit('hide');
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
<style lang="scss" scoped>
.tree-box {
  flex: 1;
  border-right: 1px solid #eee;
  padding-right: 20px;
  margin-right: 20px;
}
.table-box {
  flex: 2;
}
.title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}
.department {
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
