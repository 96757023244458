<template>
  <div class="service-reservation-detail app-container">
    <el-card shadow="never">
      <el-form
        label-suffix=":"
        label-width="100px"
        label-position="left"
      >
        <div class="is-flex-between-wrap">
          <el-form-item
            class="flex-1"
            label="服务组织名称"
          >{{ data.name }}</el-form-item>
          <el-form-item
            class="flex-1"
            label="服务组织地址"
          >{{ data.address }}</el-form-item>
        </div>
        <div class="is-flex-between-wrap">
          <el-form-item
            class="flex-1"
            label="负责人"
          >{{ data.director }}</el-form-item>
          <el-form-item
            class="flex-1"
            label="联系电话"
          >{{ data.contactDetail }}</el-form-item>
        </div>
        <el-form-item label="服务组织简介">
          {{ data.introduction }}
        </el-form-item>
        <el-form-item label="服务组织图片">
          <div>
            <img
              class="organization-image"
              v-for="(item, index) in data.serviceOrgPhotos"
              :key="index"
              :src="item.url"
            >
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getOrgReservationServiceDetail } from '@/api/org-reservation-service​';
import errorHandler from '@/plugins/error-handler';
import { formatterUrl } from '@/utils/upload';
import fileBaseUrl from '@/config/index';

export default {
  name: 'ServiceReservationDetail',
  props: {
    organizeId: {
      required: true,
    },
  },
  data() {
    return {
      fileBaseUrl,

      data: {
        name: '',
        contactDetail: '',
        address: '',
        director: '',
        introduction: '',
        serviceOrgPhotos: [],
      },
    };
  },
  created() {
    this.getOrgReservationServiceDetail();
  },
  methods: {
    async getOrgReservationServiceDetail() {
      try {
        const { data } = await getOrgReservationServiceDetail(this.organizeId);
        this.data = {
          ...this.data,
          ...data.data,
          serviceOrgPhotos: data.data.serviceOrgPhotos.map((item) => ({
            ...item,
            url: formatterUrl(this.fileBaseUrl.bucketUrl.inst, item.ossName),
          })),
        };
      } catch (error) {
        console.error(error);
        errorHandler('获取详情失败', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.service-reservation-detail {
  .flex-1 {
    flex: 1;
  }
}
.organization-image {
  width: 337px;
  height: 161px;
  margin-right: 20px;
}
</style>
