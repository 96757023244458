export default {
  // apiBaseUrl: 'http://10.66.1.22:8080/inst/api/v1',
  // apiBaseUrl: 'http://10.66.1.128:8081/inst/api/v1',
  // apiBaseUrl: 'http://10.66.1.186:8080/inst/api/v1',
  // apiBaseUrl: 'http://10.66.1.216:8080/inst/api/v1',
  // apiBaseUrl: 'http://10.66.1.91:8080/inst/api/v1',
  // apiBaseUrl: 'https://tzcare.anhetech.net/inst/api/v1',
  apiBaseUrl: 'https://znzh.zwyun.bjtzh.gov.cn/inst/api/v1',
  // apiBaseUrl: 'http://localhost:8080/inst/api/v1',
  bucketName: {
    portal: 'anhe-portal',
    inst: 'anhe-inst',
    supervise: 'anhe-care-supervise-public',
  },
  bucketUrl: {
    portal: 'https://anhe-portal.oss-cn-hangzhou.aliyuncs.com/',
    inst: 'https://anhe-inst.oss-cn-beijing.aliyuncs.com/',
    supervise: 'https://anhe-care-supervise-public.oss-cn-beijing.aliyuncs.com/',
  },
  iframeBaseUrls: {
    solution: 'http://localhost:8082/solution-web/',
  },
};
