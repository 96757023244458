import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import serviceWorkManagementActiveIcon from '@/assets/menu/service-work-management-active-icon.svg';
import serviceWorkManagementInactiveIcon from '@/assets/menu/service-work-management-inactive-icon.svg';

const routes = [
  {
    path: '/service-work-management',
    name: 'ServiceWorkManagement',
    redirect: '/service-work-management/team-management',
    meta: {
      menu: {
        name: '服务工作管理',
        icon: {
          active: serviceWorkManagementActiveIcon,
          inactive: serviceWorkManagementInactiveIcon,
        },
      },
      permissions: Ops.or([
        '老年人服务管理',
        '工作分配',
        '班组服务工作管理',
        '个人服务工作管理',
        '临时工作管理',
        '异常报警',
        '紧急工作管理',
        '工作统计',
        '班组管理',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/service-work-management/elder-service-management',
        name: 'ElderServiceManagement',
        meta: {
          menu: {
            name: '老年人服务管理',
          },
          breadcrumb: {
            label: '老年人服务管理',
          },
          permissions: '老年人服务管理',
        },
        component: () => import('@/views/service-work-management/elder-service-management/index.vue'),
      },
      {
        path: '/service-work-management/personal-distribution-management',
        name: 'PersonalDistributionManagement',
        meta: {
          menu: {
            name: '工作分配',
          },
          breadcrumb: {
            label: '工作分配',
          },
          activeMenu: '/service-work-management/personal-distribution-management',
          permissions: '工作分配',
        },
        component: () => import('@/views/service-work-management/personal-distribution-management/index.vue'),
      },
      {
        path: '/service-work-management/team-service-work-management',
        name: 'TeamServiceWorkManagement',
        meta: {
          menu: {
            name: '班组服务工作管理',
          },
          breadcrumb: {
            label: '班组服务工作管理',
          },
          permissions: '班组服务工作管理',
        },
        component: () => import('@/views/service-work-management/team-service-work-management/index.vue'),
      },
      {
        path: '/service-work-management/team-service-work-management/:id/urgencyDetail',
        name: 'TeamServiceWorkManagementUrgencyDetail',
        props: true,
        meta: {
          menu: {
            name: '紧急工作详情',
            hidden: true,
          },
          breadcrumb: {
            label: '紧急工作详情',
            parent: 'TeamServiceWorkManagement',
          },
        },
        component: () => import('@/views/service-work-management/team-service-work-management/detail.vue'),
      },
      {
        path: '/service-work-management/personal-service-work-management',
        name: 'PersonalServiceWorkManagement',
        meta: {
          menu: {
            name: '个人服务工作管理',
          },
          breadcrumb: {
            label: '个人服务工作管理',
          },
          permissions: '个人服务工作管理',
        },
        component: () => import('@/views/service-work-management/personal-service-work-management/index.vue'),
      },
      {
        path: '/service-work-management/personal-service-work-management/:id/urgencyDetail',
        name: 'PersonalServiceWorkManagementUrgencyDetail',
        props: true,
        meta: {
          menu: {
            name: '紧急工作详情',
            hidden: true,
          },
          breadcrumb: {
            label: '紧急工作详情',
            parent: 'PersonalServiceWorkManagement',
          },
        },
        component: () => import('@/views/service-work-management/personal-service-work-management/detail.vue'),
      },
      {
        path: '/service-work-management/',
        name: 'PeriodicityWorkManagement',
        meta: {
          menu: {
            name: '临时工作管理',
          },
          breadcrumb: {
            label: '临时工作管理',
          },
          permissions: '临时工作管理',
        },
        component: () => import('@/views/service-work-management/periodicity-work-management/index.vue'),
      },
      {
        path: '/service-work-management/elder-service-management/:elderId/edit',
        name: 'EditElderServiceManagement',
        props: true,
        meta: {
          menu: {
            name: '编辑老年人服务管理',
            hidden: true,
          },
          breadcrumb: {
            label: '编辑老年人服务管理',
            parent: 'ElderServiceManagement',
          },
          activeMenu: '/service-work-management/elder-service-management',
        },
        component: () => import('@/views/service-work-management/elder-service-management/edit/index.vue'),
      },
      {
        path: '/service-work-management/unusual-alarm',
        name: 'UnusualAlarm',
        meta: {
          menu: {
            name: '异常报警',
          },
          breadcrumb: {
            label: '异常报警',
          },
          permissions: '异常报警',
        },
        component: () => import('@/views/service-work-management/unusual-alarm/index.vue'),
      },
      {
        path: '/service-work-management/unusual-alarm/:id/detail',
        name: 'UnusualAlarmDetail',
        props: true,
        meta: {
          menu: {
            name: '异常报警详情',
            hidden: true,
          },
          breadcrumb: {
            label: '异常报警详情',
            parent: 'UnusualAlarm',
          },
        },
        component: () => import('@/views/service-work-management/unusual-alarm/detail/index.vue'),
      },
      {
        path: '/service-work-management/urgency-work-manage',
        name: 'UrgencyWorkManage',
        meta: {
          menu: {
            name: '紧急工作管理',
          },
          breadcrumb: {
            label: '紧急工作管理',
          },
          permissions: '紧急工作管理',
        },
        component: () => import('@/views/service-work-management/urgency-work-manage/index.vue'),
      },
      {
        path: '/service-work-management/urgency-work-manage/:id/detail',
        name: 'UrgencyWorkManageDetail',
        props: true,
        meta: {
          menu: {
            name: '紧急工作管理详情',
            hidden: true,
          },
          breadcrumb: {
            label: '紧急工作管理详情',
            parent: 'UrgencyWorkManage',
          },
          permissions: '紧急工作管理',
        },
        component: () => import('@/views/service-work-management/urgency-work-manage/detail/index.vue'),
      },
      {
        path: '/service-work-management/work-statistics',
        name: 'WorkStatistics',
        meta: {
          menu: {
            name: '工作统计',
          },
          breadcrumb: {
            label: '工作统计',
          },
          permissions: '工作统计',
        },
        component: () => import('@/views/service-work-management/work-statistics/work-statistics.vue'),
      },
      {
        path: '/service-work-management/team-management',
        name: 'TeamManagement',
        meta: {
          menu: {
            name: '班组管理',
          },
          breadcrumb: {
            label: '班组管理',
          },
          permissions: '班组管理',
        },
        component: () => import('@/views/service-work-management/team-management/index.vue'),
      },
      {
        path: '/service-work-management/team-management/add',
        name: 'TeamManagementAdd',
        meta: {
          menu: {
            name: '添加班组',
            hidden: true,
          },
          breadcrumb: {
            parent: 'TeamManagement',
            label: '添加班组',
          },
          permissions: '班组管理',
          activeMenu: '/service-work-management/team-management',
        },
        component: () => import('@/views/service-work-management/team-management/add/index.vue'),
      },
      {
        path: '/service-work-management/team-management/detail/:id',
        name: 'TeamManagementDetail',
        props: true,
        meta: {
          menu: {
            name: '查看详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'TeamManagement',
            label: '查看详情',
          },
          permissions: '班组管理',
          activeMenu: '/service-work-management/team-management',
        },
        component: () => import('@/views/service-work-management/team-management/detail/index.vue'),
      },
      {
        path: '/service-work-management/team-management/edit/:id',
        name: 'TeamManagementEdit',
        props: true,
        meta: {
          menu: {
            name: '编辑班组',
            hidden: true,
          },
          breadcrumb: {
            parent: 'TeamManagement',
            label: '编辑班组',
          },
          permissions: '班组管理',
          activeMenu: '/service-work-management/team-management',
        },
        component: () => import('@/views/service-work-management/team-management/edit/index.vue'),
      },
    ],
  },
];

export default routes;
