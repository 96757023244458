<template>
  <div class="appointment-record app-container">
    <el-card
      shadow="never"
      class="second-card-margin"
    >
      <el-form
        class="is-flex-between-wrap"
        size="small"
        label-width="100px"
      >
        <el-form-item label="服务组织名称">
          <ScrollSelect
            class="form-input-item"
            v-model="filterForm.serviceOrgId"
          />
        </el-form-item>
        <el-form-item label="老人姓名">
          <el-input
            class="form-input-item"
            v-model="filterForm.elderName"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务项目">
          <el-input
            class="form-input-item"
            v-model="filterForm.serviceOrgItemName"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务状态">
          <el-select
            class="form-input-item"
            v-model="filterForm.status"
          >
            <el-option value="已完成"></el-option>
            <el-option value="未完成"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            @click="onSearchClick"
          >查询</el-button>
          <el-button @click="onResetClick">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="never">
      <ZTable
        v-loading="loading"
        ref="table"
        :data="data"
        :current-page.sync="pagination.page"
        :page-size.sync="pagination.pageSize"
        :total="pagination.total"
        @query-change="loadData"
      >
        <el-table-column
          label="服务组织名称"
          prop="serviceOrgName"
        ></el-table-column>
        <el-table-column
          label="老人姓名"
          prop="elderName"
        ></el-table-column>
        <el-table-column
          label="联系方式"
          prop="phone"
        ></el-table-column>
        <el-table-column
          label="服务项目"
          prop="serviceOrgItemName"
        ></el-table-column>
        <el-table-column
          label="服务时间"
          :formatter="formatterTime"
        ></el-table-column>
        <el-table-column
          label="服务状态"
          prop="status"
        ></el-table-column>
        <el-table-column label="操作">
          <template v-slot="{row}">
            <el-button
              type="text"
              @click="onCheckClick(row)"
            >查看进度</el-button>
          </template>
        </el-table-column>
      </ZTable>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { pickBy } from 'lodash';
import ZTable from '@/components/z-table/index.vue';
import { getOrgReservationServiceRecords } from '@/api/org-reservation-service​';
import errorHandler from '@/plugins/error-handler';
import { getServerPageQuery } from '@/utils';
import ScrollSelect from '../components/scroll-select.vue';

export default {
  name: 'ServiceReservationProcessing',
  components: {
    ZTable,
    ScrollSelect,
  },
  data() {
    return {
      filterForm: {
        serviceOrgId: '',
        elderName: '',
        serviceOrgItemName: '',
        status: '',
      },

      loading: false,

      pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
      },

      data: [],
    };
  },
  methods: {
    async loadData(event) {
      try {
        this.loading = true;
        const { page, pageSize } = event;
        const params = {
          ...getServerPageQuery(page, pageSize),
          ...this.queryFilter(),
        };
        const { data } = await getOrgReservationServiceRecords(params);
        this.data = data.data.records;
        this.pagination.total = data.data.count;
      } catch (error) {
        console.error(error);
        errorHandler('获取列表失败', error);
      } finally {
        this.loading = false;
      }
    },

    queryFilter() {
      return pickBy(this.filterForm, (value) => value);
    },

    formatterTime(row) {
      if (row.reservationServiceTime) {
        return dayjs(row.reservationServiceTime).format('YYYY.MM.DD hh:mm');
      }
      return '';
    },

    onCheckClick(row) {
      this.$router.push({
        name: 'AppointmentRecordDetail',
        params: {
          recordId: row.id,
        },
      });
    },

    onSearchClick() {
      this.$refs.table.setPagination({ page: 1 }, true);
    },

    onResetClick() {
      this.filterForm = {
        serviceOrgId: '',
        elderName: '',
        serviceOrgItemName: '',
        status: '',
      };
      this.$refs.table.setPagination({ page: 1, pageSize: 20 }, true);
    },
  },
};
</script>

<style>
</style>
