import { request } from '@/utils/request';

// 获取机构预约列表
export function getOrgReservationServiceList(parameters) {
  return request({
    url: '/org-reservation-service/list',
    method: 'GET',
    params: parameters,
  });
}

// 获取服务组织性情
export function getOrgReservationServiceDetail(id) {
  return request({
    url: `/org-reservation-service/detail/${id}`,
    method: 'GET',
  });
}

// 获取服务组织项目列表
export function getOrgReservationServiceItemList(parameters) {
  return request({
    url: `/org-reservation-service/item/${parameters.id}`,
    method: 'GET',
    params: parameters.query,
  });
}

// 发起预约
export function initiateServiceAppointment(parameters) {
  return request({
    url: '/org-reservation-service/reservation',
    method: 'POST',
    data: parameters,
  });
}

// 获取预约记录
export function getOrgReservationServiceRecords(parameters) {
  return request({
    url: '/org-reservation-service/reservation/records',
    method: 'GET',
    params: parameters,
  });
}

// 获取预约记录详情
export function getOrgReservationServiceRecordDetail(id) {
  return request({
    url: `/org-reservation-service/reservation/detail/${id}`,
    method: 'GET',
  });
}

// 获取服务预约评价管理列表
export function getServiceEvaluationList(parameters) {
  return request({
    url: '/service-org/reservation/evaluate/list',
    method: 'GET',
    params: parameters,
  });
}

// 提交服务评价
export function submitEvaluate(parameters) {
  return request({
    url: `/org-reservation-service/reservation/${parameters.id}/evaluate`,
    method: 'PUT',
    data: parameters.data,
  });
}
