<template>
  <section class="app-main">
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped>
.app-main {
  min-height: calc(100vh - 100px);
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #f0f2f5;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
</style>
