<template>
  <el-dialog
    ref="dialog"
    :title="title"
    :visible.sync="dialogVisible"
    width="385px"
    @closed="onDialogHide"
  >
    <el-form
      size="small"
      label-suffix=":"
      label-width="85px"
    >
      <el-form-item
        label="服务项目"
        required
        :error="$et($v.form.name)"
      >
        <el-input
          class="form-input-item"
          v-model="form.name"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="项目费用"
        required
        :error="$et($v.form.fee)"
      >
        <el-input
          class="form-input-item"
          v-model="form.fee"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="服务内容"
        required
        :error="$et($v.form.content)"
      >
        <el-input
          class="form-input-item"
          v-model="form.content"
          type="textarea"
          :rows="5"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button
        size="small"
        @click="onCancelClick"
      >取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="onOKClick"
      >确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/plugins/error-handler';
import { editServiceOrgItem } from '@/api/service-org';
import { clone } from '@/utils';

export default {
  props: {
    title: {
      default: '新建服务项目',
    },
    data: {
      required,
    },
  },
  data() {
    return {
      dialogVisible: false,

      form: {
        name: '',
        fee: '',
        content: '',
      },
    };
  },
  created() {
    this.form = clone(this.data);
  },
  validations: {
    form: {
      name: {
        required,
      },
      fee: {
        required,
      },
      content: {
        required,
      },
    },
  },
  errors: {
    required: '该项不能为空',
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },

    onDialogHide() {
      this.$emit('hide');
    },

    async onOKClick() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.loading = true;
        const params = {
          id: this.form.id,
          data: {
            name: this.form.name,
            fee: this.form.fee,
            content: this.form.content,
          },
        };
        await editServiceOrgItem(params);
        this.$emit('ok');

        this.hide();
      } catch (error) {
        console.error(error);
        errorHandler('添加失败', error);
      } finally {
        this.loading = false;
      }
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>

<style>
</style>
