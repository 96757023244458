import Layout from '@/views/layout/index.vue';
import * as Ops from '@/plugins/v-permission/ops/index';
import onlineActiveIcon from '@/assets/menu/online-active-icon.svg';
import onlineInactiveIcon from '@/assets/menu/online-inactive-icon.svg';

const routes = [
  {
    path: '/online-service',
    name: 'OnlineService',
    meta: {
      menu: {
        name: '线上服务',
        icon: {
          active: onlineActiveIcon,
          inactive: onlineInactiveIcon,
        },
      },
      permissions: Ops.or([
        '项目管理',
        '订单管理',
        '居家工作管理',
        '完成记录',
      ]),
    },
    component: Layout,
    children: [
      {
        path: '/online-service/project-management',
        name: 'ProjectManagement',
        meta: {
          menu: {
            name: '项目管理',
          },
          breadcrumb: {
            label: '项目管理',
          },
          permissions: '项目管理',
        },
        component: () => import('@/views/online-service/project-management/index.vue'),
      },
      {
        path: '/online-service/project-management/service-classify-detail/:id',
        name: 'ServiceClassifyDetail',
        props: true,
        meta: {
          menu: {
            name: '项目详情',
            hidden: true,
          },
          breadcrumb: {
            label: '项目详情',
            parent: 'ProjectManagement',
          },
          permissions: '项目管理',
          activeMenu: '/online-service/project-management',
        },
        component: () => import('@/views/online-service/project-management/service-classify/detail/index.vue'),
      },
      {
        path: '/online-service/project-management/service-project-detail/:id',
        name: 'ServiceProjectDetail',
        props: true,
        meta: {
          menu: {
            name: '项目详情',
            hidden: true,
          },
          breadcrumb: {
            label: '项目详情',
            parent: 'ProjectManagement',
          },
          permissions: '项目管理',
          activeMenu: '/online-service/project-management',
        },
        component: () => import('@/views/online-service/project-management/sercice-project/detail/index.vue'),
      },
      {
        path: '/online-service/order-management',
        name: 'OrderManagement',
        meta: {
          menu: {
            name: '订单管理',
          },
          breadcrumb: {
            label: '订单管理',
          },
          permissions: '订单管理',
        },
        component: () => import('@/views/online-service/order-management/index.vue'),
      },
      {
        path: '/online-service/housework-management',
        name: 'HouseworkManagement',
        meta: {
          menu: {
            name: '居家工作管理',
          },
          breadcrumb: {
            label: '居家工作管理',
          },
          permissions: '居家工作管理',
        },
        component: () => import('@/views/online-service/housework-management/index.vue'),
      },
      {
        path: '/online-service/completion-record',
        name: 'CompletionRecord',
        meta: {
          menu: {
            name: '完成记录',
          },
          breadcrumb: {
            label: '完成记录',
          },
          permissions: '完成记录',
        },
        component: () => import('@/views/online-service/completion-record/index.vue'),
      },
      {
        path: '/online-service/return-visit-record',
        name: 'ReturnVisitRecord',
        meta: {
          menu: {
            name: '服务回访',
          },
          breadcrumb: {
            label: '服务回访',
          },
        },
        component: () => import('@/views/online-service/return-visit-record/return-visit-record.vue'),
      },
      {
        path: '/online-service/work-statistics',
        name: 'OnlineServiceWorkStatistics',
        meta: {
          menu: {
            name: '服务统计',
          },
          breadcrumb: {
            label: '服务统计',
          },
        },
        component: () => import('@/views/online-service/online-work-statistics/work-statistics.vue'),
      },
    ],
  },
];

export default routes;
