import { request } from '@/utils/request';

export function login(parameters) {
  return request({
    url: '/user/login',
    method: 'POST',
    data: parameters,
  });
}

export function getKeyPair() {
  return request({
    url: '/user/key-pair',
    method: 'POST',
  });
}

export function loginBy2fa(parameters) {
  return request({
    url: '/user/login-2fa',
    method: 'POST',
    data: parameters,
  });
}

// 登录
export function LoginBySafe(data) {
  return request({
    url: '/user/login-safe',
    method: 'POST',
    data,
  });
}

// 获取用户信息
export function getUserInfo() {
  return request({
    url: '/user/personal/info',
    method: 'GET',
  });
}

// 获取上传文件token
export function getUploadToken(parameters) {
  return request({
    url: '/user/token',
    method: 'GET',
    params: parameters,
  });
}

// 修改密码
export function modifyPassword(parameters) {
  return request({
    url: '/user/edit/password',
    method: 'PUT',
    data: parameters,
  });
}

// 发送验证码
export function getVercode(parameters) {
  return request({
    url: '/user/vercode/send',
    method: 'POST',
    data: parameters,
  });
}

// 重置密码
export function resetPassword(parameters) {
  return request({
    url: '/user/forget/password',
    method: 'PUT',
    data: parameters,
  });
}

// 获取账号 所属机构列表
export function getOrganizationList(parameters) {
  return request({
    url: '/user/organization/list',
    method: 'get',
    params: parameters,
  });
}

// 选择登录机构
export function selectOrganaization(orgId) {
  return request({
    url: `/user/organization/${orgId}/login`,
    method: 'put',
  });
}

// 设置默认机构
export function setDefaultOrganaization(orgId) {
  return request({
    url: `/user/organization/${orgId}/default`,
    method: 'put',
  });
}

// 切换机构
export function switchOrganaization(orgId) {
  return request({
    url: `/user/organization/${orgId}/switch`,
    method: 'put',
  });
}

// 清空默认机构
export function cleanOrganaization() {
  return request({
    url: '/user/organization/clean',
    method: 'put',
  });
}

// 上传文件
export function handleUploadFile(parameters) {
  return request({
    url: '/common/file-upload/',
    method: 'PUT',
    params: parameters.query,
    data: parameters.data,
  });
}
