const mutations = {
  SET_DEPOSIT_RETURN: (state, { type, depositId }) => {
    state.depositReturn = {
      type,
      depositId,
    };
  },
  SET_DEPOSIT_PAY: (state, { type, depositId }) => {
    state.depositPay = {
      type,
      depositId,
    };
  },

  SET_CURRENTCHECKINTAB: (state, currentCheckInTab) => {
    state.currentCheckInTab = currentCheckInTab;
  },
};

export default mutations;
