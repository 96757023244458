<template>
  <div class="elderly-layout-header">
    <div class="header-title">
      养老照护机构信息管理系统
    </div>
    <div class="header-menu">
      {{name}},您好!
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['name']),
  },
};
</script>

<style lang="scss" scoped>
.elderly-layout-header {
  width: 100%;
  height: 50px;
  padding: 12px 30px;
  box-sizing: border-box;
  background: #fa4f74;
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-title {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
  }
  .header-menu {
    font-size: 14px;
    color: #fff;
    line-height: 22px;
    .header-menu-item {
      padding: 0 10px;
    }
    .header-menu-item:last-child {
      padding: 0 0 0 10px;
      border-left: 1px solid #fff;
    }
  }
}
</style>
