import { getToken } from '@/utils/local-store-token';

export default () => ({
  token: getToken(),
  account: '',
  roles: [],
  positionList: [],
  menuList: [],
  permissions: new Set(),
  name: '',
  orgName: '',
  unreadMessage: '',
  organizationType: '',
  needChangePwd: false,
});
