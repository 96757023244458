<template>
  <el-select
    v-bind="$attrs"
    :value="value"
    @input="getSelected"
    placeholder="请选择"
    @visible-change="onVisibleChange"
    filterable
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :value="item.id"
      :label="item.name"
    ></el-option>
    <div
      v-show="!isLastPage"
      class="el-select-dropdown__item--load-more"
      ref="loadmore"
    >
      <i class="el-icon-loading"></i>
      加载更多...
    </div>
  </el-select>
</template>

<script>
import { getServerPageQuery } from '@/utils/index';
import { getServiceOrgReservationOrgList } from '@/api/service-org';

export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      observer: null,
      pagination: {
        page: 1,
        pageSize: 20,
      },
      data: [],
      total: 0,
    };
  },
  computed: {
    isLastPage() {
      const lastPage = Math.ceil(this.total / this.pagination.pageSize);
      return this.pagination.page === lastPage;
    },
  },
  mounted() {
    this.loadData();
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  methods: {
    getSelected(event) {
      this.$emit('input', event);
    },

    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const parentElement = target.offsetParent;
        const { scrollTop } = target.offsetParent;
        if (!this.isLastPage) {
          this.pagination.page += 1;
        }
        this.loadData();
        await this.$nextTick();
        parentElement.scrollTop = scrollTop;
      }
    },
    async loadData() {
      const { page, pageSize } = this.pagination;
      const params = getServerPageQuery(page, pageSize);
      const { data } = await getServiceOrgReservationOrgList(params);
      this.data = this.data.concat(data.data.records);
      this.total = data.data.count;
    },

    async onVisibleChange(visable) {
      await this.$nextTick();
      if (visable) {
        this.observer.observe(this.$refs.loadmore);
      } else {
        this.observer.disconnect();
      }
    },
  },
};
</script>

<style>
</style>
