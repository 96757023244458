import dayjs from 'dayjs';

export function formatterMonth(time) {
  return time ? dayjs(time).format('YYYY年MM月') : '';
}
export function formatterDate(time) {
  return time ? dayjs(time).format('YYYY-MM-DD') : '';
}
export function formatterDateTimeMinute(time) {
  return time ? dayjs(time).format('YYYY-MM-DD HH:mm') : '';
}
export function formatterDateTime(time) {
  return time ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : '';
}
export function formatterYear(time) {
  return time ? dayjs(time).format('YYYY') : '';
}
// 获取年龄
export function getAgeBaseOnBirthday(birthday) {
  if (!birthday) {
    return '';
  }
  return dayjs().diff(birthday, 'years');
}
