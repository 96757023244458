import Homepage from './modules/homepage';
import OnlineService from './modules/online-service';
import ElderlyService from './modules/elderly-service';
import ElderlyHealth from './modules/elderly-health';
import ElderlyManagement from './modules/elderly-management';
import SeniorManagement from './modules/senior-management';
import ServiceWorkManagement from './modules/service-work-management';
import GovernmentApplication from './modules/government-application';
import OrganizationalStructure from './modules/organizational-structure';
import Recruit from './modules/recruit-center';
import FinanceMangement from './modules/finance-management';
import PersonalCenter from './modules/personal-center';
import Institutions from './modules/institutions';
import ServiceContentManagement from './modules/service-content-management';
import StorageManagement from './modules/accessories-management';
import PharmacyManagement from './modules/pharmacy-management';
import MedicationManagement from './modules/medication-management';
import ServiceRecords from './modules/service-records';
import TrainingManage from './modules/training-manage';
import FoodManage from './modules/food-manage';
import MessageCenter from './modules/message-center';
import HardwareManage from './modules/hardware-manage';
import HardwareDataObserve from './modules/hardware-data-observe';
import ServiceOrganizationManagement from './modules/service-organization-management';
import InstitutionalReservationService from './modules/institutional-reservation-service';
import AffairsReport from './modules/affairs-report';
import Roster from './modules/roster';
import BedOverview from './modules/bed-overview';
import TaskList from './modules/task-list';
import SolutionManage from './modules/solution-management';

const dynamicRoutes = [
  ...Homepage,
  ...OnlineService,
  ...ElderlyService,
  ...ElderlyHealth,
  ...ElderlyManagement,
  ...BedOverview,
  ...SeniorManagement,
  ...GovernmentApplication,
  ...Roster,
  ...OrganizationalStructure,
  ...SolutionManage,
  ...ServiceWorkManagement,
  ...ServiceContentManagement,
  ...ServiceRecords,
  ...TrainingManage,
  ...FoodManage,
  ...Recruit,
  ...FinanceMangement,
  ...PersonalCenter,
  ...StorageManagement,
  ...Institutions,
  ...PharmacyManagement,
  ...MedicationManagement,
  ...MessageCenter,
  ...HardwareManage,
  ...HardwareDataObserve,
  ...ServiceOrganizationManagement,
  ...InstitutionalReservationService,
  ...AffairsReport,
  ...TaskList,
];

export default dynamicRoutes;
