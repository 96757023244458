<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: [String, Object],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  render(h, context) {
    const { icon, title, isActive } = context.props;
    const vnodes = [];

    if (icon) {
      const iconLength = Object.keys(icon).length;
      if (iconLength === 0 && icon.includes('el-icon')) {
        vnodes.push(<i class={[icon, 'sub-el-icon']} />);
      } else {
        let activeIcon = '';
        if (isActive) {
          activeIcon = icon.active;
        } else {
          activeIcon = icon.inactive;
        }

        // vnodes.push(<svg-icon icon-class={icon} />);
        vnodes.push(<img src={activeIcon} class="svg-icon" />);
      }
    }

    if (title) {
      vnodes.push(<span slot="title">{title}</span>);
    }
    return vnodes;
  },
};
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}
.svg-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
  overflow: hidden;
}
</style>
