import { request } from '@/utils/request';

// 获取消息列表
export function getMessageList(parameters) {
  return request({
    url: '/message/',
    method: 'GET',
    params: parameters,
  });
}

// 阅读消息
export function readMessage(id) {
  return request({
    url: `/message/${id}`,
    method: 'GET',
  });
}

// 全部已读
export function readAllMessage() {
  return request({
    url: '/message/read/all',
    method: 'POST',
  });
}

// 获取消息数量
export function getMessageNumber() {
  return request({
    url: '/message/number',
    method: 'POST',
  });
}
